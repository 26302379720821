<template>
  <div class="empty-toast-root">
    <FontAwesomeIcon
      v-if="icon?.length"
      :icon="icon"
      class="icon-size-1"
      :class="iconClass"
    />

    <span class="text">{{ text }}</span>

    <button v-if="showCloseButton" class="btn circle" @click="closeToast">
      <FontAwesomeIcon icon="fa-light fa-xmark" class="icon-size-1" />

      <!-- TODO localize -->
      <span class="sr-only">Close toast</span>
    </button>
  </div>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { toRefs } from 'vue'
import { type ToastOptions } from 'vue3-toastify'

const props = withDefaults(
  defineProps<{
    closeToast?: (e?: MouseEvent) => void
    toastProps?: ToastOptions
    text: string
    icon?: string
    iconClass?: string
    showCloseButton: boolean
  }>(),
  {
    text: 'Toast',
    showCloseButton: true
  }
)
const { closeToast, text, icon, iconClass, showCloseButton } = toRefs(props)
</script>
<style scoped>
.empty-toast-root {
  display: flex;
  gap: var(--size-1);
  align-items: center;

  color: var(--gray-12);
}

.text {
  flex: 1;
  font-variation-settings: 'wght' 500;
}
</style>
