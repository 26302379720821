export enum duplicateSubscriptionError {
  None,
  BraintreeBraintree,
  BraintreeApple,
  AppleApple
}

export enum VaultItemType {
  File,
  Folder,
  Image,
  Video
}

export enum VaultColumnFilter {
  All,
  Starred,
  Recent,
  Files,
  Folders,
  SelectedFolder,
  Images,
  Videos,
  Trashed
}

export enum VaultSortBy {
  NameAsc,
  NameDesc,
  CreatedDateAsc,
  CreatedDateDesc,
  FileSizeAsc,
  FileSizeDesc
}

export enum VaultListViewMode {
  List,
  Card,
  Grid
}

export enum VaultThumbnailStatus {
  Generating,
  Generated
}

export enum CallingCallType {
  Phone,
  Video,
  Voicemail
}

export enum CallingCallDirection {
  Incoming,
  Outgoing
}

export enum CallingFilter {
  All,
  PhoneCalls,
  VideoCalls,
  Voicemails
}

export enum VideoCallStatus {
  WaitingRoom,
  WaitingRoomAbandoned,
  WaitingRoomTimedOut,
  CoparentAccepted,
  CoparentRejected,
  MidCall,
  CreatorDisconnected,
  CoparentDisconnected,
  CreatorCompleted,
  CoparentCompleted,
  BalanceEmptyCompleted,
  Failed,
  WebhookCompleted,
  Reconnecting,
  Connecting
}

export enum FinalCallingStatus {
  Unanswered,
  Incomplete,
  Successful
}

export enum SortFilterFieldsetTypes {
  Dropdown,
  Checkboxes,
  RadioButtons
}

export enum MessagesSortFilterSortOptions {
  NewestToOldest,
  OldestToNewest,
  Relevance,
  All
}

export enum MessagesSortFilterFilterOptions {
  Subject,
  Attachment,
  Message,
  UnreadOnly
}

export enum MessagesSortFilterSentOptions {
  Both,
  Coparent,
  You
}

export enum MessagesSortFilterDateOptions {
  AllTime,
  Today,
  ThisWeek,
  ThisYear,
  LastYear,
  Custom
}

export enum OwnerFilter {
  None,
  Mine,
  Coparent
}

export enum FileType {
  Document,
  Image,
  Video,
  Audio,
  Spreadsheet,
  Presentation
}

export enum SortOption {
  Relevance,
  DateDescending,
  DateAscending,
  ContentAscending,
  ContentDescending,
  SizeAscending,
  SizeDescending
}

export enum ColumnLayoutColumnState {
  Hidden,
  Visible,
  Collapsible,
  Static
}

export enum GlobalSearchResultType {
  Conversation,
  Message,
  Attachment,
  CalendarEvent,
  CalendarEventRecurrence,
  JournalEntry,
  JournalAttachment,
  InfoLibraryCard,
  VaultItem,
  Payment,
  PaymentRequest,
  ScheduledPayment,

  TranscriptSegment,
  PhoneCall,
  VideoCall,
  Voicemail,
  GoogleAd
}

export enum DownloadStatus {
  Downloading,
  Stopped,
  Completed,
  Failed
}

export enum MessagesFilterByType {
  All,
  Subject,
  Message,
  Attachment
}

export enum HttpStatusCodes {
  Continue = 100,
  SwitchingProtocols,
  Processing,
  EarlyHints,
  OK = 200,
  Created,
  Accepted,
  NonAuthoritativeInformation,
  NoContent,
  ResetContent,
  PartialContent,
  MultiStatus,
  AlreadyReported,
  IMUsed = 226,
  MultipleChoices = 300,
  MovedPermanently,
  Found,
  SeeOther,
  NotModified,
  UseProxy,
  TemporaryRedirect = 307,
  PermanentRedirect,
  BadRequest = 400,
  Unauthorized,
  PaymentRequired,
  Forbidden,
  NotFound,
  MethodNotAllowed,
  NotAcceptable,
  ProxyAuthenticationRequired,
  RequestTimeout,
  Conflict,
  Gone,
  LengthRequired,
  PreconditionFailed,
  ContentTooLarge,
  URITooLong,
  UnsupportedMediaType,
  RangeNotSatisfiable,
  ExpectationFailed,
  MisdirectedRequest = 421,
  UnprocessableContent,
  Locked,
  FailedDependency,
  TooEarly,
  UpgradeRequired,
  PreconditionRequired = 428,
  TooManyRequests,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented,
  BadGateway,
  ServiceUnavailable,
  GatewayTimeout,
  HTTPVersionNotSupported,
  VariantAlsoNegotiates,
  InsufficientStorage,
  LoopDetected,
  NetworkAuthenticationRequired = 511
}

export enum CreateRoomError {
  ServerError,
  VersionMismatch,
  TwilioError,
  CoparentDisabledVideoCalls,
  NoMinutes,
  UserNotPremium,
  FeatureDisabled,
  RecipientHasInvalidTier
}

export enum ConversationItemType {
  Reply = 1,
  ThreadViewEvent,
  AttachmentViewEvent,
  Attachment,
  CallEvent,
  VideoCallEvent,
  VideoVoicemailEvent
}

export enum GetPagedThreadResultError {
  ServerError
}
