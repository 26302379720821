// src/plugins/rewardful.ts
export default {
  install: (app: any, options: { key: string }) => {
    // Initialize rewardful function
    ;(function (w: any, r: string) {
      w._rwq = r
      w[r] =
        w[r] ||
        function (...args: any[]) {
          ;(w[r].q = w[r].q || []).push(args)
        }
    })(window, 'rewardful')

    // Inject the Rewardful script
    const script = document.createElement('script')
    script.src = 'https://r.wdfl.co/rw.js'
    script.async = true
    script.setAttribute('data-rewardful', options.key)
    document.head.appendChild(script)

    // Optionally expose it globally in Vue
    app.config.globalProperties.$rewardful = window.rewardful
  }
}
