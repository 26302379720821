﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import helpers from '@/exports/helper'
import calHelpers from '../pages/calendar/calendarHelpers'
import { useCommonStore } from './CommonStore'
import httpClient from '@/httpClient'
import type {
  ICalDay,
  ICalendarEntry,
  IFetchCalendarByDatesPayload,
  IFetchDeletedListPayload,
  IHandleEntriesPayloads
} from '@/models/interfaces'
import { CalendarEntry } from '@/models/interfaces'
import type { ICalendarState } from '@/models/stores/calendar'
import { DateTime } from 'luxon'
import type {
  ICancelOccurrenceWebRequest,
  ISetEventColorRequest
} from '@/models/models'
import constants from '@/exports/constants'

const initialState = (): ICalendarState => ({
  currentDay: DateTime.local(),
  viewType: constants.viewTypes.month,
  viewTypeName: constants.viewTypeNames.month,
  unseenEvents: [] as ICalendarEntry[],
  calendarDays: [] as ICalDay[],
  dayEvents: [] as ICalendarEntry[],
  weekIndex: 0,
  entry: {
    itemID: 0,
    subject: '',
    notes: '',
    startTime: DateTime.local(),
    endTime: DateTime.local(),
    isRecurring: false,
    isAllDay: false,
    isNew: false,
    color: 'default-color',
    rRule: '',
    dtStart: null,
    until: null,
    ownerID: 0,
    parentItemID: null,
    lastEdited: null,
    lastViewed: null,
    createdWhen: null,
    isCanceled: false 
  } as ICalendarEntry,
  placeholderEntries: [
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    }),
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    }),
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    })
  ] as ICalendarEntry[],
  numberOfWeeks: 0,
  showEventDetails: false,
  showEditDialog: false,
  showCancelDialog: false,
  showDeleteConfirmation: false,
  showColorPicker: false,
  occurrenceDay: null,
  startTimeInUtc: DateTime.local({ zone: 'utc' }).startOf('month').startOf('week', { useLocaleWeeks: true }).startOf('day'),
  endTimeInUtc: DateTime.local({ zone: 'utc' }).endOf('month').endOf('week', { useLocaleWeeks: true }).endOf('day'),
  forceRefresh: false,
  selectedColorFromPicker: 'default-color',
  isFetchingAPI: false,
  enableSkeleton: true,
  newDeletedEvents: false
})

const paths: string[] = []

export const useCalendarStore = defineStore('calendar', {
  state: initialState,
  getters: {
    getCurrentDayAsFormattedString(state) {
      return state.currentDay?.toFormat('yyyy-MM-dd')
    },
    getRecurringEventText(state) {
      let eventText = 'This event'
      if (state.entry.isAllDay) {
        eventText = 'This all day event'
      }
      if (calHelpers.isEventMultiDay(state.entry as ICalendarEntry)) {
        eventText = 'This multi-day event'
      }
      eventText += ` will repeat ${calHelpers.getRRuleAsText(
        state.entry.rRule,
        this.entry.startTime
      )}`
      return eventText
    },
    getDaysForMobileMonthView(state): ICalDay[] {
      const _days: ICalDay[] = state.calendarDays.filter((day) =>
        calHelpers.isDayInMonth(day.day, state.currentDay)
      )
      return _days
    },
    isMultiDay: () => {
      return (entry: ICalendarEntry): boolean => calHelpers.isMultiDay(entry)
    },
    getNumberOfUnseenEvents(state) {
      return state.unseenEvents.length
    },
    monthHasEvent(): boolean {
      // dont check events if not pulled in yet
      if (this.calendarDays.length == 0) {
        return true
      }
      const _days = this.getDaysForMobileMonthView

      return _days.findIndex((d) => d.events.length > 0) >= 0
    },
    isDayView(state): boolean {
      return state.viewType == constants.viewTypes.day
    },
    getUserTimezone(): string {
      const commonStore = useCommonStore()
      return commonStore.getDateTimeSettings.timeZoneNameForMomentJs
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof ICalendarState)[])
      )
    },
    async setLoadingOnCommon(payload: boolean): Promise<void> {
      const common = useCommonStore()
      common.loading = payload
    },
    async saveEntry(
      entry: ICalendarEntry,
      editingSeries: boolean
    ): Promise<string> {
      const _entry = {
        ...entry,
        startTime: entry.startTime.toUTC().toJSDate(),
        endTime: entry.endTime.toUTC().toJSDate(),
        dtStart: entry.startTime.toUTC().toJSDate(),
        until: entry.until
          ? entry.until.toUTC().toJSDate()
          : DateTime.local({ zone: this.getUserTimezone }).plus({ years: 100 }).toJSDate(),
        createdInTimeZone: this.getUserTimezone,
        occurenceDateTime:
          entry.isRecurring && !editingSeries
            ? this.occurrenceDay?.toJSDate()
            : DateTime.local({ zone: this.getUserTimezone }).plus({ years: 100 }).toJSDate()
      }

      let url = '/web/api/Calendar/CreateEntry'

      if (entry.itemID == 0) {
        url = '/web/api/Calendar/CreateEntry'
      } else if (entry.itemID != 0 && !_entry.isRecurring) {
        url = '/web/api/Calendar/EditEntry'
      } else if (_entry.isRecurring && editingSeries) {
        url = '/web/api/Calendar/EditSeries'
      } else if (_entry.isRecurring && !editingSeries) {
        url = '/web/api/Calendar/EditOccurrence'
      }

      try {
        const response = await httpClient.post(url, _entry)

        if (response.data.success) {
          this.forceRefresh = true
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'saveEntry')
        return 'error'
      }
    },
    async updateEventColor(payload: ISetEventColorRequest): Promise<string> {
      const url = '/web/api/Calendar/UpdateEventColor'

      try {
        const response = await httpClient.post(url, payload)

        if (response.data.success) {
          return response.data.value.color
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateEventColor')
        return 'error'
      }
    },
    async fetchCalendarByDates(
      payload: IFetchCalendarByDatesPayload
    ): Promise<void> {
      try {
        if (this.enableSkeleton) {
          this.isFetchingAPI = true
        } else {
          this.isFetchingAPI = false
        }

        const url = `/web/api/Calendar/GetEntries?StartTimeInUtc=${payload.startDate}&EndTimeInUtc=${payload.endDate}`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
        switch (payload.viewType) {
          case constants.viewTypes.month:
            await this.handleEntries({
              startDate: payload.startDate,
              endDate: payload.endDate,
              events: response.data.value.events
            })
            break
          case constants.viewTypes.week:
            await this.handleEntriesForWeek({
              startDate: payload.startDate,
              endDate: payload.endDate,
              events: response.data.value.events
            })
            break
          case constants.viewTypes.day:
            await this.handleEntriesForDay({
              day: payload.startDate,
              events: response.data.value.events
            })
            break
        }
        if (this.viewType == constants.viewTypes.week) {
          this.weekIndex = this.getWeekIndexOfCurrentDay()
        }
        if (this.viewType == constants.viewTypes.day) {
          const calDate = this.calendarDays.find((calDay) => {
            return DateTime.fromISO(calDay.day).hasSame(
              this.currentDay,
              'day'
            )
          })
          this.dayEvents = calDate?.events || []
        }
        this.startTimeInUtc = DateTime.fromISO(payload.startDate, { zone: 'utc'})
        this.endTimeInUtc = DateTime.fromISO(payload.endDate, { zone: 'utc'})

        this.forceRefresh = false
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCalendarByDates')
      } finally {
        this.isFetchingAPI = false
        this.setLoadingOnCommon(false)
      }
    },
    getWeekIndexOfCurrentDay(): number {
      const dayIndex = this.calendarDays.findIndex((calDay) =>
        DateTime.fromISO(calDay.day).hasSame(
          this.currentDay,
          'day'
        )
      )
      return dayIndex == -1 ? -1 : Math.round(dayIndex / 7)
    },
    async getDayOrFetch(payload: IFetchCalendarByDatesPayload): Promise<void> {
      // check with state.calendarDays to get day events.
      // if the date does not exist fetch that month from API
      const _startDate = DateTime.fromISO(payload.startDate) // in user timezone

      const calDate = this.calendarDays.find((calDay) => 
        DateTime.fromISO(calDay.day).hasSame(_startDate, 'day')
      )

      if (calDate && !this.forceRefresh) {
        this.dayEvents = calDate.events
        this.setLoadingOnCommon(false)
      } else {
        // get the whole month worth of events
        const startDateUtc = calHelpers.getStartDateFromParamsForApiCall(_startDate, this.getUserTimezone).toISO()
        const endDateUtc = calHelpers.getEndDateFromParamsForApiCall(_startDate,  this.getUserTimezone).toISO()

        if (startDateUtc && endDateUtc) {
          this.fetchCalendarByDates({
            startDate: startDateUtc,
            endDate: endDateUtc,
            viewType: constants.viewTypes.month
          })
        }
      }
    },
    async getWeekOrFetch(payload: IFetchCalendarByDatesPayload): Promise<void> {
      if (this.weekIndex != this.getWeekIndexOfCurrentDay()) {
        // if we have the week already, don't fetch it again
        this.weekIndex = this.getWeekIndexOfCurrentDay()
      }
      if (
        this.weekIndex == -1 ||
        this.weekIndex > this.numberOfWeeks - 1 ||
        this.forceRefresh
      ) {
        // if we don't have the week, fetch it
        this.fetchCalendarByDates({
          startDate: payload.startDate,
          endDate: payload.endDate,
          viewType: constants.viewTypes.month
        })
      } else {
        this.setLoadingOnCommon(false)
      }
    },
    async fetchDeletedList(
      payload: IFetchDeletedListPayload
    ): Promise<ICalendarEntry[]> {
      try {
        this.isFetchingAPI = true
        const url = '/web/api/Calendar/GetDeletedList'
        const params = {
          PageNumber: payload.pageNumber,
          PageSize: payload.pageSize
        }

        const response = await httpClient.get(url, { params: params })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        const events = await this.handleDeletedEntries({
          events: response.data.value.events
        })

        return events
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchDeletedList')
      } finally {
        this.setLoadingOnCommon(false)
        this.isFetchingAPI = false
      }

      return []
    },
    async handleDeletedEntries(
      payload: IHandleEntriesPayloads
    ): Promise<ICalendarEntry[]> {
      // find events for the current day
      const timezone = this.getUserTimezone
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      return _events
      //commit('setDeletedEvents', _events)
    },
    async handleEntriesForWeek(payload: IHandleEntriesPayloads): Promise<void> {
      // find events for the current day
      const timezone = this.getUserTimezone
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      this.dayEvents = calHelpers.getEventsForDate(
        this.currentDay,
        _events
      )
      await this.handleEntries(payload)
    },
    async handleEntriesForDay(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = this.getUserTimezone
      // convert then check
      const _events = calHelpers.convertEventDatesToUsersLocalTime(payload.events, timezone)

      // do a quick filter to make sure there are not any rouge recurring events
      const ev = _events.filter((ev) =>
        calHelpers.isEventInDay(
          ev,
          payload.day || DateTime.local().toISO(),
          timezone
        )
      )
      this.dayEvents = ev
    },
    async handleEntries(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = this.getUserTimezone
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )

      // create calendar days with events
      const calStartDate = DateTime
        .fromISO(payload.startDate as unknown as string, { zone: 'utc' })
        .setZone(timezone)

      const calEndDate = DateTime
        .fromISO(payload.endDate as unknown as string, { zone: 'utc' })
        .setZone(timezone)

      const numWeeks = (calEndDate.diff(calStartDate, 'day').days + 1) / 7

      const calendarDays = []
      let firstDate = calStartDate
  
      while (firstDate <= calEndDate) {
        const dayString = firstDate.toISODate()
        if (dayString) {
          const calDay = {
            day: dayString,
            events: calHelpers.getEventsForDate(
              firstDate,
              _events
            )
          }
          calendarDays.push(calDay)
        }

        firstDate = firstDate.plus({ day: 1 })
      }

      this.calendarDays = calendarDays // add events to current month

      this.numberOfWeeks = numWeeks
    },
    async fetchNewEventsData(): Promise<void> {
      try {
        // if (this.enableSkeleton) {
        //   this.isFetchingAPI = true
        // } else {
        //   this.isFetchingAPI = false
        // }
        this.isFetchingAPI = true
        const url = '/web/api/Calendar/GetNewList'
        const response = await httpClient.get(url)
        if (response?.status !== 200)
          throw new Error(response?.data?.errorMessage)

        this.handleNewEntries({
          events: response.data.value.events
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchNewEventsData')
      } finally {
        this.isFetchingAPI = false
        this.setLoadingOnCommon(false)
      }
    },
    async handleNewEntries(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = this.getUserTimezone

      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      this.unseenEvents = _events
    },
    async fetchEntry(itemId: number): Promise<ICalendarEntry | undefined> {
      try {
        const timezone = this.getUserTimezone
        const url = '/web/api/Calendar/GetEntry?itemId=' + itemId
        const response = await httpClient.get(url)
        if (response.status !== 200) throw new Error(response.data.errorMessage)
        const _entry = response.data.value.event

        const entry: ICalendarEntry = calHelpers.convertEventToUsersLocalTime(_entry, this.getUserTimezone)

        return entry
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchEntry')
      } finally {
        this.setLoadingOnCommon(false)
      }
    },
    async getNewEntry(itemId: number): Promise<ICalendarEntry | undefined> {
      try {
        const url = '/web/api/Calendar/GetEntry?itemId=' + itemId
        const response = await httpClient.get(url)
        if (response.status !== 200) throw new Error(response.data.errorMessage)
        const _entry = response.data.value.event

        const convertedEntry = { 
          ...calHelpers.convertEventToUsersLocalTime(_entry, this.getUserTimezone),
          isNew: true
        }

        return convertedEntry
      } catch (e) {
        ErrorHelper.handleError(e, 'getNewEntry')
      }
    },
    async deleteEntry(eventId: number): Promise<string> {
      //this.enableSkeleton = false;
      const url = '/web/api/Calendar/DeleteEntry?itemId=' + eventId

      try {
        const response = await httpClient.get(url)

        if (response.data.success) {
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteEntry')
        this.setLoadingOnCommon(false)
        return 'error'
      }
    },
    async cancelOccurrence(
      payload: ICancelOccurrenceWebRequest
    ): Promise<string> {
      const url = '/web/api/Calendar/CancelOccurrence'

      try {
        const response = await httpClient.post(url, payload)

        if (response.data.success) {
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'cancelOccurrence')
        this.setLoadingOnCommon(false)
        return 'error'
      }
    },
    removeFromUnseenEvents(eventId: number): void {
      const _ue: ICalendarEntry[] = Object.assign(this.unseenEvents)
      //find event from id
      const _eventIndex = _ue.findIndex((e) => e.itemID == eventId)
      if (_eventIndex > -1) {
        _ue.splice(_eventIndex, 1)
        this.unseenEvents = _ue
      }
    },
    async handleCalendarNotifications(
      type: number,
      itemId?: number
    ): Promise<void> {
      //type: 0 - new event; 1 - altered event; 2 - deleted event

      // get unseen events, get badge counts, if on calendar, fetchCalendarByDates
      const common = useCommonStore()
      await common.fetchBadgeCounts()

      switch (type) {
        case 0:
        case 1:
          await this.fetchNewEventsData()
          break
        case 2:
          if (
            window.location.pathname.toLocaleLowerCase().includes('calendar')
          ) {
            await this.fetchCalendarByDates({
              startDate: this.startTimeInUtc.toUTC().toISO() ?? '',
              endDate: this.endTimeInUtc.toUTC().toISO() ?? '',
              viewType: this.viewType
            })

            const deletedUnseenEventIndex = this.unseenEvents
              .map((ue) => ue.itemID)
              .indexOf(itemId ?? -1)

            if (deletedUnseenEventIndex) {
              this.unseenEvents = this.unseenEvents.filter(
                (ue, index) => index != deletedUnseenEventIndex
              )
            }

            this.newDeletedEvents = true
          }
          break
      }
    },
    setEntry(val: ICalendarEntry): void {
      this.entry = val
    },
    setEntryStartTime(startTime: DateTime): void {
      this.entry.startTime = startTime
    },
    setEntryEndTime(endTime: DateTime): void {
      this.entry.endTime = endTime
    },
    setEntryUntil(until: DateTime): void {
      this.entry.until = until
    },
    setShowEventDetails(value: boolean): void {
      this.showEventDetails = value
    },
    setShowEditDialog(value: boolean): void {
      this.showEditDialog = value
    },
    setOccurrenceDay(value: DateTime): void {
      this.occurrenceDay = value
    },
    setShowCancelDialog(value: boolean): void {
      this.showCancelDialog = value
    },
    setShowDeleteConfirmation(value: boolean): void {
      this.showDeleteConfirmation = value
    },
    setShowColorPicker(value: boolean): void {
      this.showColorPicker = value
    },
    setWeekIndexPlusOne(): void {
      this.weekIndex = this.weekIndex + 1
    },
    setWeekIndexMinusOne(): void {
      this.weekIndex = this.weekIndex - 1
    },
    setCurrentDay(day: DateTime): void {
      this.currentDay = day
    },
    setViewType(viewType: number): void {
      this.viewType = viewType
    },
    setViewTypeName(viewTypeName: string): void {
      this.viewTypeName = viewTypeName
    },
    getDays(index: number) {
      return this.calendarDays.slice(index * 7, index * 7 + 7)
    },
    getDaysForMonth() {
      return this.calendarDays.slice(0, this.numberOfWeeks * 7)
    },
    isEditable: (event: ICalendarEntry) => {
      const commonStore = useCommonStore()
      return event == null
        ? false
        : event.ownerID == commonStore.fullUserInfo.userId
    },
    getClassForEventDisplay(entry: ICalendarEntry) {
      const commonStore = useCommonStore()
      const defaultColor =
        entry.ownerID == commonStore.fullUserInfo.userId
          ? 'cerulean'
          : 'slategray'
      let _class = entry.color.length > 0 ? entry.color : defaultColor

      if (entry.isCanceled) {
        _class = 'canceled-event'
      }
      return _class
    },
    getRecurringEventTextMethod(entry: ICalendarEntry, creatingEvent = false) {
      let eventText = ''

      if (creatingEvent) {
        eventText = 'This event'

        if (entry.isAllDay) {
          eventText = 'This all-day event'
        }
        if (calHelpers.isEventMultiDay(entry)) {
          eventText = 'This multi-day event'
        }
        eventText += ` will repeat ${calHelpers.getRRuleAsText(
          entry.rRule,
          entry.startTime,
          this.getUserTimezone
        )}.`
      } else {
        eventText += calHelpers
          .getRRuleAsText(
            entry.rRule,
            entry.dtStart ?? DateTime.local(),
            this.getUserTimezone
          )
          .trim()

        eventText = eventText.charAt(0).toUpperCase() + eventText.slice(1)
      }

      return eventText
    },
    setColorFromPicker(color: string) {
      this.selectedColorFromPicker = color
    },
    getDayOfTheWeek(date: DateTime): string {
      return date.toFormat('cccc').toLowerCase()
    },
    setNewDeletedEvents(newDeletedEvents: boolean) {
      this.newDeletedEvents = newDeletedEvents
    }
  }
})
