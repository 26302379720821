import { defineStore, storeToRefs } from 'pinia'
import { type ThreadViewModel } from '@/models/models'
import httpClient from '@/httpClient'
import ErrorHelper from '@/exports/error'
import type {
  ICachedReply,
  ICachedReplyPreview,
  IConversation,
  IConversationViewModel,
  ICallIdForNewConversation,
  IDateRange,
  ILatestThreadsPayload,
  IPagedPayload,
  IPagedThreadPayload,
  IReplyDraft,
  IConversationItem,
  IGetPagedThreadResult
} from '@/models/interfaces'
import { useCommonStore } from './CommonStore'
import type { IMessagesState } from '@/models/stores/messages'
import { MessagingThread } from '@/models/models'
import type {
  IGlobalSearchResult,
  ISearchConversationsPagedRequest
} from '@/models/interfaces.ts'
import {
  GlobalSearchResultType,
  MessagesFilterByType,
  OwnerFilter,
  SortOption
} from '@/models/enums.ts'
import helper from '@/exports/helper'
import { DateTime } from 'luxon'
import { useAccountSettingsStore } from './AccountSettingsStore'

const pageSize = 25
const previewLength = 250
const adArray = [3, 10]

const initialState = (): IMessagesState => ({
  messageThreads: [{}, {}, {}],
  count: 0,
  selectedMessageThread: {
    subject: '',
    timeZoneIdentifier: '',
    threadItems: [],
    createdBy: '',
    createDate: DateTime.now()
  },
  selectedConversationSearchResult: {
    type: GlobalSearchResultType.Conversation,
    conversation: { itemID: 0 }
  },
  newReplyAlert: { newReply: false, threadId: 0 },
  newThreadAlert: { newThread: false, threadId: 0 },
  requestId: '',
  loadMoreMessages: false,
  threadLoaded: false,
  searchedMessageThreads: [
    {
      type: GlobalSearchResultType.Conversation,
      message: {
        itemID: 0,
        content: '\xa0',
        creatorID: 0,
        createDate: DateTime.now()
      },
      conversation: {
        itemID: 0,
        title: '\xa0',
        isNew: false,
        lastMessageDate: DateTime.now()
      },
      skeletonLoading: true
    },
    {
      type: GlobalSearchResultType.Conversation,
      message: {
        itemID: 0,
        content: '\xa0',
        creatorID: 0,
        createDate: DateTime.now()
      },
      conversation: {
        itemID: 0,
        title: '\xa0',
        isNew: false,
        lastMessageDate: DateTime.now()
      },
      skeletonLoading: true
    },
    {
      type: GlobalSearchResultType.Conversation,
      message: {
        itemID: 0,
        content: '\xa0',
        creatorID: 0,
        createDate: DateTime.now()
      },
      conversation: {
        itemID: 0,
        title: '\xa0',
        isNew: false,
        lastMessageDate: DateTime.now()
      },
      skeletonLoading: true
    }
  ],
  loadMoreSearchResults: false,
  groupedSearchedMessages: [{ replies: [], subject: '' }],
  threadListSearchTerm: '',
  threadListPage: 1,
  threadListScrollPosition: 0,
  sortFilterSelectedOptions: {
    page: 1,
    searchTerm: '',
    ownerFilter: OwnerFilter.None,
    filterByType: MessagesFilterByType.Subject,
    unreadOnly: false,
    sortBy: SortOption.DateDescending
  },
  selectedMatchedTokens: [],
  conversationListIndexedDBIds: [],
  replyDrafts: [],
  fetchingConversations: false,
  fetchingMoreConversations: false,
  fetchingItems: false,
  fetchingMoreItems: false,
  callIdForNewConversation: {},
  downloadingAttachmentItemId: null
})

const paths: string[] = ['selectedMessageThread']

export const useMessagesStore = defineStore('messages', {
  state: initialState,
  persist: {
    paths: paths
  },
  getters: {
    getLastReplyItemID: (state) => {
      let itemId = 0
      const _ti = state.selectedMessageThread.threadItems

      if (_ti) {
        const revTi = [..._ti]
        revTi.reverse()
        const _item = revTi.find((el) => el.itemType == 1)
        itemId = _item?.itemID || 0
      }

      return itemId
    },
    areFiltersDefault: (state) => {
      const commonStore = useCommonStore()
      const { searchTerm } = storeToRefs(commonStore)

      return (
        state.sortFilterSelectedOptions.ownerFilter == OwnerFilter.None &&
        ((!searchTerm.value.length &&
          state.sortFilterSelectedOptions.filterByType ==
            MessagesFilterByType.Subject) ||
          (!!searchTerm.value.length &&
            state.sortFilterSelectedOptions.filterByType ==
              MessagesFilterByType.All)) &&
        state.sortFilterSelectedOptions.unreadOnly == false &&
        state.sortFilterSelectedOptions.sortBy ==
          (searchTerm.value.length
            ? SortOption.Relevance
            : SortOption.DateDescending)
      )
    },
    previewLength: () => previewLength,
    getSearchedMessageThreadsCount: (state) => {
      return state.searchedMessageThreads.filter((thread) => thread.type != GlobalSearchResultType.GoogleAd).length
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helper.omit(initialState(), paths as (keyof IMessagesState)[])
      )

      this.selectedMessageThread = {
        subject: '',
        timeZoneIdentifier: '',
        threadItems: [],
        createdBy: '',
        createDate: DateTime.now()
      }
    },
    async fetchThreadsPaged(payload: IPagedPayload) {
      try {
        const url = `/web/api/Messaging/GetConversationsPaged?PageNumber=${payload.page}&PageSize=${pageSize}&PreviewLength=${previewLength}`

        // Call V2 for the API to get new preview message data
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _messageThreads =
          payload.page == 1
            ? response.data.value
            : [...this.messageThreads, ...response.data.value]

        //duplicate check based on ID
        this.messageThreads = Array.from(
          new Set(_messageThreads.map((m: IConversation) => m.threadID))
        )
          .filter((i) => i != undefined)
          .map((threadId) => {
            const thread = _messageThreads.find(
              (m: IConversation) => m.threadID === threadId
            )

            //these two come back as strings from the API, need them to be DateTimes
            thread.lastReplyDate = DateTime.fromISO(thread.lastReplyDate, {
              zone: 'utc'
            })
            thread.createDate = DateTime.fromISO(thread.createDate, {
              zone: 'utc'
            })

            return thread
          }) as IConversation[]


        const accountSettingsStore = useAccountSettingsStore()
        const commonStore = useCommonStore()
        if (accountSettingsStore.subscriptionT0 && commonStore.featureFlags?.web_messages_enable3ColumnLayout) {
          this.count = _messageThreads.length - this.insertAdsForFreeUsers(this.messageThreads, adArray, { hasGoogleAd: true })
        } else {
          this.count = _messageThreads.length
        }
        
        this.loadMoreMessages = response.data.value.length == pageSize
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchThreadsPaged')
      }
    },
    // gets latest threads and appends older pages to the end
    async updateCurrentThreads() {
      try {
        const url = `/web/api/Messaging/GetConversationsPaged?PageNumber=1&PageSize=${pageSize}&PreviewLength=${previewLength}`

        // Call V2 for the API to get new preview message data
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const updatedThreads = response.data.value as IConversation[]
        const oldThreads = this.messageThreads

        // remove ads
        const accountSettingsStore = useAccountSettingsStore()
        if (accountSettingsStore.subscriptionT0) {
          adArray.forEach((ad) => {
            oldThreads.splice(ad, 1)
          })
        }

        const _messageThreads = [...updatedThreads, ...this.messageThreads]

        // diff old threads
        this.messageThreads = Array.from(
          new Set(_messageThreads.map((m: IConversation) => m.threadID))
        )
          .filter((i) => i != undefined)
          .map((threadId) => {
            const thread = _messageThreads.find(
              (m: IConversation) => m.threadID === threadId
            )

            //these two come back as strings from the API, need them to be DateTimes
            if (typeof thread?.lastReplyDate == 'string') {
              thread.lastReplyDate = DateTime.fromISO(thread.lastReplyDate, {
                zone: 'utc'
              })
            }

            if (typeof thread?.createDate == 'string') {
              thread.createDate = DateTime.fromISO(thread.createDate, {
                zone: 'utc'
              })
            }

            return thread
          }) as IConversation[]

        if (accountSettingsStore.subscriptionT0) {
          this.count = this.messageThreads.length - this.insertAdsForFreeUsers(this.messageThreads, adArray, { hasGoogleAd: true })
        } else {
          this.count = this.messageThreads.length
        }
        this.loadMoreMessages = true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchThreadsPaged')
      }
    },
    async updateSearchedThreads(payload: ISearchConversationsPagedRequest) {
      try {
        // get nearest page
        const minimumPageSize =
          this.getSearchedMessageThreadsCount +
          (pageSize - (this.getSearchedMessageThreadsCount % pageSize))
        
        let url = `/mobile/api/Messaging/Search?SearchTerm=${encodeURIComponent(
          payload.searchTerm
        )}&PageNumber=1&PageSize=${minimumPageSize}&PreviewLength=${previewLength}`

        if (payload.ownerFilter != undefined) {
          url += `&OwnerFilter=${payload.ownerFilter}`
        }

        if (payload.filterByType != undefined) {
          url += `&FilterByType=${payload.filterByType}`
        }

        if (payload.unreadOnly != undefined) {
          url += `&UnreadOnly=${payload.unreadOnly}`
        }

        if (payload.attachmentFileTypes != undefined) {
          for (const fileType of payload.attachmentFileTypes) {
            url += `&AttachmentFileTypes=${fileType}`
          }
        }

        if (payload.dateFilter != undefined) {
          url += `&DateFilter.StartDate=${payload.dateFilter.startDate}&DateFilter.EndDate=${payload.dateFilter.endDate}`
        }

        if (payload.sortBy != undefined) {
          url += `&SortBy=${payload.sortBy}`
        }

        // Call V2 for the API to get new preview message data
        //now v3 for cognitive search
        //and v5 for sorting and filtering
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '5'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _messageThreads = response.data.value as IGlobalSearchResult[]

        const accountSettingsStore = useAccountSettingsStore()
        if (accountSettingsStore.subscriptionT0) {
          this.insertAdsForFreeUsers(_messageThreads, adArray, { type: GlobalSearchResultType.GoogleAd })
        }
        
        this.searchedMessageThreads = _messageThreads
        this.loadMoreSearchResults = !response.data.allResultsLoaded
        this.setThreadListPage(minimumPageSize / pageSize)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchSearchThreadsPaged')
      }
    },
    async fetchSearchThreadsPaged(payload: ISearchConversationsPagedRequest) {
      try {
        let url = `/mobile/api/Messaging/Search?SearchTerm=${encodeURIComponent(
          payload.searchTerm
        )}&PageNumber=${
          payload.page
        }&PageSize=${pageSize}&PreviewLength=${previewLength}`

        if (payload.ownerFilter != undefined) {
          url += `&OwnerFilter=${payload.ownerFilter}`
        }

        if (payload.filterByType != undefined) {
          url += `&FilterByType=${payload.filterByType}`
        }

        if (payload.unreadOnly != undefined) {
          url += `&UnreadOnly=${payload.unreadOnly}`
        }

        if (payload.attachmentFileTypes != undefined) {
          for (const fileType of payload.attachmentFileTypes) {
            url += `&AttachmentFileTypes=${fileType}`
          }
        }

        if (payload.dateFilter != undefined) {
          url += `&DateFilter.StartDate=${payload.dateFilter.startDate}&DateFilter.EndDate=${payload.dateFilter.endDate}`
        }

        if (payload.sortBy != undefined) {
          url += `&SortBy=${payload.sortBy}`
        }

        // Call V2 for the API to get new preview message data
        //now v3 for cognitive search
        //and v5 for sorting and filtering
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '5'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _messageThreads = (
          payload.page == 1
            ? response.data.value
            : [...this.searchedMessageThreads, ...response.data.value]
        ) as IGlobalSearchResult[]

        this.searchedMessageThreads = _messageThreads.map(
          (thread: IGlobalSearchResult) => {
            if (thread.message?.createDate) {
              thread.message.createDate = DateTime.fromISO(
                thread.message.createDate as unknown as string,
                {
                  zone: 'utc'
                }
              )
            }

            if (thread.conversation?.lastMessageDate) {
              thread.conversation.lastMessageDate = DateTime.fromISO(
                thread.conversation.lastMessageDate as unknown as string,
                {
                  zone: 'utc'
                }
              )
            }

            if (thread.attachment?.uploadDateTimeUtc) {
              thread.attachment.uploadDateTimeUtc = DateTime.fromISO(
                thread.attachment.uploadDateTimeUtc as unknown as string,
                {
                  zone: 'utc'
                }
              )
            }
            return thread
          }
        )

        const accountSettingsStore = useAccountSettingsStore()
        const commonStore = useCommonStore()
        if (accountSettingsStore.subscriptionT0 && commonStore.featureFlags?.web_messages_enable3ColumnLayout) {
          this.insertAdsForFreeUsers(this.searchedMessageThreads, adArray, { type: GlobalSearchResultType.GoogleAd })
        }

        this.loadMoreSearchResults = !response.data.allResultsLoaded
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchSearchThreadsPaged')
      }
    },
    async fetchPagedThread(payload: IPagedThreadPayload) {
      try {
        this.threadLoaded = false

        const pageSize = payload.pageSize || 100
        const url = `/web/api/Conversations/GetPagedThread?ThreadID=${payload.threadID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response?.data.success) {
          this.selectedMessageThread = {
            subject: '',
            timeZoneIdentifier: '',
            threadItems: [],
            createdBy: '',
            createDate: DateTime.now()
          }
          throw new Error(response?.data?.errorMessage)
        }

        const _thread: IConversationViewModel = response.data.value

        const commonStore = useCommonStore()

        //this one comes back from the API already in the user's timezone without an offset attached
        _thread.createDate = DateTime.fromISO(
          _thread.createDate as unknown as string,
          {
            zone: commonStore.fullUserInfo.timeZoneNameForMomentJs ?? 'utc'
          }
        )

        const _threadItems = _thread.threadItems
        _threadItems?.map((item) => {
          item.entryDate = DateTime.fromISO(
            item.entryDate as unknown as string,
            {
              zone: 'utc'
            }
          )
          item.entryDateUtc = DateTime.fromISO(
            item.entryDateUtc as unknown as string,
            {
              zone: 'utc'
            }
          )
          if (item.altEntryDateUtc) {
            item.altEntryDateUtc = DateTime.fromISO(
              item.altEntryDateUtc as unknown as string,
              {
                zone: 'utc'
              }
            )
          }

          return item
        })

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ..._thread,
          ownerUserID: _thread.userID
        }

        if (_threadItems?.length == 0) {
          this.threadLoaded = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPagedThread')
      }
    },
    async combineThreadItems(items: IConversationItem[]) {
      const _existingItems = this.selectedMessageThread.threadItems || []

      // add new, not duplicate, items to array
      const ids = new Set(_existingItems.map((d) => d.itemID)) || []
      const newItems = items.filter(
        (d: IConversationItem) => !ids.has(d.itemID)
      )
      const combinedArray = [..._existingItems, ...newItems]

      // order array by EntryDate
      combinedArray.sort((a, b) => {
        const da = (typeof a.entryDateUtc == 'string' ? DateTime.fromISO(a.entryDateUtc) : a.entryDateUtc) ?? DateTime.now(),
          db = (typeof b.entryDateUtc == 'string' ? DateTime.fromISO(b.entryDateUtc) : b.entryDateUtc) ?? DateTime.now()
        return da.diff(db).toObject().milliseconds ?? 0
      })

      return combinedArray
    },
    async fetchMorePagedThreadItems(payload: IPagedThreadPayload) {
      try {
        this.threadLoaded = false

        const pageSize = payload.pageSize || 100
        const url = `/web/api/Conversations/GetPagedThread?ThreadID=${payload.threadID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response.data.success) throw new Error(response.data.errorMessage)

        const _responseCamel: IGetPagedThreadResult = response.data.value

        const _newMessageThread = _responseCamel as IConversationViewModel
        const _threadItems = _newMessageThread?.threadItems || []

        if (!_newMessageThread) {
          this.threadLoaded = true
          return
        }

        _newMessageThread.createDate = DateTime.fromISO(
          _newMessageThread.createDate as unknown as string
        )

        // if no more thread items, set variable and return
        if (_threadItems && _threadItems?.length == 0) {
          this.threadLoaded = true
          return
        }

        _threadItems.map((item) => {
          item.entryDate = DateTime.fromISO(
            item.entryDate as unknown as string,
            {
              zone: 'utc'
            }
          )
          item.entryDateUtc = DateTime.fromISO(
            item.entryDateUtc as unknown as string,
            {
              zone: 'utc'
            }
          )
          if (item.altEntryDateUtc) {
            item.altEntryDateUtc = DateTime.fromISO(
              item.altEntryDateUtc as unknown as string,
              {
                zone: 'utc'
              }
            )
          }

          return item
        })

        _newMessageThread.threadItems =
          await this.combineThreadItems(_threadItems)

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ..._newMessageThread,
          ownerUserID: _newMessageThread.userID
        }

        return
        // if (_threadItems.length > pageSize) {
        //   this.fetchMorePagedConversations({
        //     threadID: payload.threadID,
        //     mostRecent: _threadItems[0].entryDateUtc?.toDateString()
        //   })

        //   this.allMessageThreadsLoaded = false
        // } else {
        //   this.allMessageThreadsLoaded = true
        // }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchMorePagedThreadItems')
      }
    },
    async fetchLatestThreadItems(payload: ILatestThreadsPayload) {
      try {
        this.threadLoaded = false

        const pageSize = 100
        const url = `/web/api/Conversations/GetlatestThreadItems?ThreadID=${payload.threadID}&ItemID=${payload.itemID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url))
        const _responseCamel: IGetPagedThreadResult = response.data

        if (!_responseCamel.success)
          throw new Error(_responseCamel.errorMessage)

        const _newMessageThread = _responseCamel.value

        if (!_newMessageThread) return

        const _threadItems = _newMessageThread?.threadItems || []

        // if no more thread items, set variable and return
        if (_threadItems && _threadItems.length == 0) {
          this.threadLoaded = true
          return
        }

        _threadItems.map((item) => {
          item.entryDate = DateTime.fromISO(
            item.entryDate as unknown as string,
            {
              zone: 'utc'
            }
          )
          item.entryDateUtc = DateTime.fromISO(
            item.entryDateUtc as unknown as string,
            {
              zone: 'utc'
            }
          )
          if (item.altEntryDateUtc) {
            item.altEntryDateUtc = DateTime.fromISO(
              item.altEntryDateUtc as unknown as string,
              {
                zone: 'utc'
              }
            )
          }

          return item
        })

        _newMessageThread.threadItems =
          await this.combineThreadItems(_threadItems)

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ...{ threadItems: _newMessageThread.threadItems }
        }

        this.threadLoaded = true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchLatestThreadItems')
      }
    },
    async createMessage(payload: FormData): Promise<boolean> {
      const url = '/web/api/Messaging/CreateMessage'
      const abortController = new AbortController()
      const common = useCommonStore()

      try {
        payload.append('RequestId', this.requestId)
        common.abortController = abortController

        const response = await httpClient.post(url, payload, {
          signal: abortController.signal,
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-version': '2'
          }
        })

        this.setUID()

        if (!response.data.success) {
          const err = new Error(response.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'createMessage',
            response.data.errorCode > 0,
            response.data.errorMessage
          )
          return false
        }

        return true
      } catch (e) {
        if (e == 'Canceled') return false
        ErrorHelper.handleError(e, 'saveReply')
        return false
      }
    },
    async createThread(payload: FormData) {
      const url = '/web/api/Messaging/CreateConversation'
      const abortController = new AbortController()
      const common = useCommonStore()

      try {
        payload.append('requestId', this.requestId)
        common.abortController = abortController

        const response = await httpClient.post(url, payload, {
          signal: abortController.signal,
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-version': '3'
          }
        })

        this.setUID()

        if (!response.data.success) {
          const err = new Error(response.data.errorMessage)

          if (response.data.errorCode == 2) {
            ErrorHelper.handleError(
              err,
              'createThread',
              response.data.errorCode == 2,
              'You can only create one new message every 30 seconds. Please try again later.'
            )
          } else {
            ErrorHelper.handleError(
              err,
              'createThread',
              response.data.errorCode > 0,
              response.data.errorMessage
            )
          }
        } else {
          const _response = await response.data.threadID
          return _response
        }
      } catch (e) {
        if (e == 'Canceled') return
        // errorCode 2 = Too many requests
        ErrorHelper.handleError(
          e,
          'createThread',
          true,
          'You can only create one new message every 30 seconds. Please try again later.'
        )
      }
    },
    handleNewThreadNotifications(threadId: number | undefined, subject = '') {
      subject
      const common = useCommonStore()

      common.fetchBadgeCounts()

      this.newThreadAlert = { newThread: true, threadId: threadId ?? 0 }
    },
    handleNewReplyNotifications(senderId: number, threadId: number) {
      const common = useCommonStore()

      common.fetchBadgeCounts()
      this.newReplyAlert = { newReply: true, threadId: threadId, senderId: senderId }
    },
    resetSelectedMessageThread() {
      this.selectedMessageThread = {
        subject: '',
        timeZoneIdentifier: '',
        threadItems: [],
        createdBy: '',
        createDate: DateTime.now()
      }
    },
    setSelectedMessageThread(selectedMessageThread: IConversation) {
      this.selectedMessageThread = selectedMessageThread
    },
    setUID() {
      // Generate the UID from two parts here
      // to ensure the random number provide enough bits.
      const firstPart = (Math.random() * 46656) | 0
      const secondPart = (Math.random() * 46656) | 0

      this.requestId =
        ('000' + firstPart.toString(36)).slice(-3) +
        ('000' + secondPart.toString(36)).slice(-3)
    },
    setSelectedConversationSearchResult(
      selectedConversationSearchResult: IGlobalSearchResult
    ) {
      this.selectedConversationSearchResult = selectedConversationSearchResult
    },
    setThreadListSearchTerm(threadListSearchTerm: string) {
      this.threadListSearchTerm = threadListSearchTerm
    },
    setThreadListPage(threadListPage: number) {
      this.threadListPage = threadListPage
    },
    setThreadListScrollPosition(threadListScrollPosition: number) {
      this.threadListScrollPosition = threadListScrollPosition
    },
    setSortFilterSelectedOptions(options: ISearchConversationsPagedRequest) {
      this.sortFilterSelectedOptions = { ...options }
    },
    setOwnerFilter(filter: OwnerFilter) {
      this.sortFilterSelectedOptions.ownerFilter = filter
    },
    setFilterByType(type: MessagesFilterByType) {
      this.sortFilterSelectedOptions.filterByType = type
    },
    setUnreadOnly(unread: boolean) {
      this.sortFilterSelectedOptions.unreadOnly = unread
    },
    setSortBy(sort: SortOption) {
      this.sortFilterSelectedOptions.sortBy = sort
    },
    setDateFilter(filter: IDateRange) {
      this.sortFilterSelectedOptions.dateFilter = filter
    },
    resetSearchedMessageThreads() {
      this.searchedMessageThreads = [
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createDate: DateTime.now()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: DateTime.now()
          },
          skeletonLoading: true
        },
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createDate: DateTime.now()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: DateTime.now()
          },
          skeletonLoading: true
        },
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createDate: DateTime.now()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: DateTime.now()
          },
          skeletonLoading: true
        }
      ]
    },
    setLoadMoreMessages(loadMore: boolean) {
      this.loadMoreMessages = loadMore
    },
    resetMessageThreads() {
      this.messageThreads = [{}, {}, {}]
    },
    resetThreads() {
      this.resetMessageThreads()
      this.resetSearchedMessageThreads()
      this.loadMoreMessages = false
      this.loadMoreSearchResults = false
    },
    clearFilters() {
      const commonStore = useCommonStore()
      const { searchTerm } = storeToRefs(commonStore)

      const filterByType = searchTerm.value.length
        ? MessagesFilterByType.All
        : MessagesFilterByType.Subject

      const sortBy = searchTerm.value.length
        ? SortOption.Relevance
        : SortOption.DateDescending

      this.sortFilterSelectedOptions = {
        page: 1,
        searchTerm: '',
        ownerFilter: OwnerFilter.None,
        filterByType: filterByType,
        unreadOnly: false,
        sortBy: sortBy
      }
    },
    setSelectedMatchedTokens(tokens: string[]) {
      this.selectedMatchedTokens = tokens
    },
    getCachedReplyFromIndexedDB(threadId: number) {
      const commonStore = useCommonStore()

      return commonStore.getFromIndexedDB('cachedReplies', 'threadId', [
        threadId
      ])
    },
    getCachedReplyPreviewFromIndexedDB(threadId: number) {
      const commonStore = useCommonStore()

      const template: ICachedReplyPreview = {
        threadId: 0,
        userId: 0,
        messagePreview: new ArrayBuffer(),
        previewIv: new Uint8Array(),
        salt: ''
      }

      return commonStore.getFromIndexedDB(
        'cachedReplies',
        'threadId',
        [threadId],
        template
      )
    },
    getCachedRepliesFromIndexedDB(threadIds: number[]) {
      const commonStore = useCommonStore()

      return commonStore.getFromIndexedDB(
        'cachedReplies',
        'threadId',
        threadIds
      )
    },
    getCachedReplyPreviewsFromIndexedDB(threadIds: number[]) {
      const commonStore = useCommonStore()

      const template: ICachedReplyPreview = {
        threadId: 0,
        userId: 0,
        messagePreview: new ArrayBuffer(),
        previewIv: new Uint8Array(),
        salt: ''
      }

      return commonStore.getFromIndexedDB(
        'cachedReplies',
        'threadId',
        threadIds,
        template
      )
    },
    postCachedReplyToIndexedDB(reply: ICachedReply) {
      const commonStore = useCommonStore()

      return commonStore.postToIndexedDB('cachedReplies', 'threadId', reply)
    },
    putCachedReplyToIndexedDB(reply: ICachedReply) {
      const commonStore = useCommonStore()

      return commonStore.putToIndexedDB('cachedReplies', 'threadId', reply)
    },
    deleteCachedReplyFromIndexedDB(threadId: number) {
      const commonStore = useCommonStore()

      return commonStore.deleteFromIndexedDB('cachedReplies', 'threadId', [
        threadId
      ])
    },
    deleteAllCachedRepliesFromIndexedDB() {
      const commonStore = useCommonStore()

      return commonStore.deleteFromIndexedDB('cachedReplies', 'threadId', [])
    },
    addReplyDraft(draft: IReplyDraft) {
      const existingDraft = this.replyDrafts.find(
        (d) => d.threadId == draft.threadId
      )

      if (!existingDraft) {
        this.replyDrafts.push(draft)
        return
      }

      existingDraft.message = draft.message
    },
    removeReplyDraft(id: number) {
      this.replyDrafts = this.replyDrafts.filter((d) => d.threadId != id)
    },
    getAllCachedRepliesFromIndexedDB() {
      const commonStore = useCommonStore()

      return commonStore.getFromIndexedDB('cachedReplies', 'threadId', [])
    },
    getAllCachedReplyPreviewsFromIndexedDB() {
      const commonStore = useCommonStore()

      const template: ICachedReplyPreview = {
        threadId: 0,
        userId: 0,
        messagePreview: new ArrayBuffer(),
        previewIv: new Uint8Array(),
        salt: ''
      }

      return commonStore.getFromIndexedDB(
        'cachedReplies',
        'threadId',
        [],
        template
      )
    },
    setFetchingConversations(fetching: boolean) {
      this.fetchingConversations = fetching
    },
    setFetchingMoreConversations(fetching: boolean) {
      this.fetchingMoreConversations = fetching
    },
    setFetchingItems(fetching: boolean) {
      this.fetchingItems = fetching
    },
    setFetchingMoreItems(fetching: boolean) {
      this.fetchingMoreItems = fetching
    },
    setCallIdForNewConversation(
      callIdToAttach: ICallIdForNewConversation | null
    ) {
      this.callIdForNewConversation = callIdToAttach
    },
    setNewThreadAlert(threadId: number) {
      this.newThreadAlert = { newThread: true, threadId: threadId }
    },
    setNewReplyAlert(threadId: number, senderId?: number) {
      this.newReplyAlert = { newReply: true, threadId: threadId, senderId: senderId }
    },
    async setup3ColumnLayoutFromRoute(to: any) {
      const commonStore = useCommonStore()
      // don't do anything in base path
      if (to.name == 'messages') {
        return
      }

      // get params then redirect
      if (
        to.name == 'messagesEntry' // messages/new = messagesEntry
      ) {
        commonStore.setDoAddNew(true)
        this.setSelectedMessageThread({})
        this.setCallIdForNewConversation(null)
      } else if (
        to.name == 'messagesEntryPhoneCall' // messages/new/callLogItemID
      ) {
        commonStore.setDoAddNew(true)
        this.setSelectedMessageThread({})
        this.setCallIdForNewConversation({
          callLogItemID: Array.isArray(to.params.callLogItemID)
            ? to.params.callLogItemID[0]
            : to.params.callLogItemID
        })
      } else if (
        to.name == 'messagesEntryVideoCall' // messages/new/videoCallItemId
      ) {
        commonStore.setDoAddNew(true)
        this.setSelectedMessageThread({})
        this.setCallIdForNewConversation({
          videoCallItemId: Array.isArray(to.params.videoCallItemId)
            ? to.params.videoCallItemId[0]
            : to.params.videoCallItemId
        })
      } else if (
        to.name == 'messagesEntryVoicemail' // messages/new/videoVoicemailItemID
      ) {
        commonStore.setDoAddNew(true)
        this.setSelectedMessageThread({})
        this.setCallIdForNewConversation({
          videoVoicemailItemID: Array.isArray(to.params.videoVoicemailItemID)
            ? to.params.videoVoicemailItemID[0]
            : to.params.videoVoicemailItemID
        })
      } else if (to.name == 'viewMessage') {
        //commonStore.setDoAddNew(false)

        const date = new Date()
        date.setFullYear(date.getFullYear() + 1)
        const isoString = date.toISOString()

        const threadId = parseInt(to.params.itemId as string)

        const _item = {
          threadID: threadId,
          subject: '',
          ownerUserID: commonStore.fullUserInfo.userId,
          threadItems: [
            {
              itemType: 1,
              userID: 0
            }
          ]
        }

        this.setSelectedMessageThread(_item)

        await this.fetchPagedThread({
          threadID: threadId,
          mostRecent: isoString,
          pageSize: 20
        })
      }
    },
    setDownloadingAttachmentItemId(id: number | null) {
      this.downloadingAttachmentItemId = id
    },
    // used for actions that don't necessarily need a fetch - creating new convo,
    setThreadListItemViewedLocal(threadId: number) {
      // find thread
      const threadPos = this.messageThreads.findIndex(
        (thread) => thread.threadID == threadId
      )
      if (threadPos == -1) {
        return
      }
      this.messageThreads[threadPos].isNew = false

      // get last Reply
      if (
        this.selectedMessageThread.threadID == threadId &&
        this.selectedMessageThread.threadItems &&
        this.selectedMessageThread.threadItems?.length > 0
      ) {
        //lastReply = this.selectedMessageThread.threadItems
        // find the first item type from the last
        // start from the end of the threadItems -- we expect the reply to be within the last ones unless they have a bunch of calls or downloads events after
        for (
          let i = this.selectedMessageThread.threadItems.length - 1;
          i >= 0;
          i--
        ) {
          if (
            this.selectedMessageThread.threadItems[i] &&
            this.selectedMessageThread.threadItems[i].itemType == 1
          ) {
            this.messageThreads[threadPos].lastReplyPreview =
              this.selectedMessageThread.threadItems[i].message
            this.messageThreads[threadPos].lastReplyUserID =
              this.selectedMessageThread.threadItems[i].userID
            this.messageThreads[threadPos].lastReplyDate =
              this.selectedMessageThread.threadItems[i].entryDateUtc
            return
          }
        }
      }
    },
    insertAdsForFreeUsers(arr: any[], adIndexes: number[], adObj: {}) {
      // place an add for each index unless it's bigger than the length
      let insertedCount = 0
      adIndexes.forEach((adPlace, adCount) => {
        if ((adPlace + adCount) <= arr.length) {
          insertedCount += 1
          arr.splice(adPlace + adCount, 0, { ...adObj, key: Math.random() })
        }
      })

      return insertedCount
    },
    doesThreadHaveUpdate(threadId: number) {
      const foundThread = this.messageThreads.find((thread) => thread.threadID == threadId) || this.searchedMessageThreads.find((result) => result.conversation?.itemID == threadId)?.conversation
      if (foundThread === undefined) return false
      return foundThread.isNew
    }
  }
})
