import axios from 'axios'
import { Mutex } from 'async-mutex'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import router from '@/router'
import { useCommonStore } from '@/stores/CommonStore'
import { useLoginStore } from '@/stores/LoginStore'
import { useRoute } from 'vue-router'

// declare module 'axios' {
//   export interface AxiosRequestConfig {
//     'api-version'?: string
//   }
// }

async function requestInterceptor(config: any) {
  const commonStore = useCommonStore()
  const loginStore = useLoginStore()
  if (commonStore.isOnline) {
    if (!config.headers['Authorization']) {
      const token = loginStore.authToken
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
        const decodedToken = jwt_decode(token)

        if (
          isTokenExpired(decodedToken) &&
          config?.url !== '/web/api/Token/Login'
        ) {
          if (mutex.isLocked()) {
            return queueRequest(config, 'config')
          }
          const release = await mutex.acquire()
          return await refreshToken(config, 'config', release)
        }
      }
    }
    const _traceUser = commonStore.fullUserInfo.traceUser
    if (import.meta.env.VITE_NODE_ENV != 'production' || _traceUser) {
      config.headers['Trace-User'] = '1'
    } else {
      config.headers['Trace-User'] = '0'
    }
    return config
  } else {
    throw new Error('Network Error')
  }
}

function isTokenExpired(decodedToken: any) {
  return Date.now() >= decodedToken.exp * 1000
}

let requestQueue: any[] = []
const mutex = new Mutex()

const queueRequest = async (config: any, returnType: any) => {
  try {
    const token = await new Promise(function (resolve, reject) {
      requestQueue.push({ resolve, reject })
    })
    config.headers['Authorization'] = 'Bearer ' + token
    return returnType == 'client' ? httpClient(config) : config
  } catch (error) {
    return await Promise.reject(error)
  }
}

const processQueue = (error: any, token = null) => {
  requestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  requestQueue = []
}

async function refreshToken(config: any, returnType: any, releaseMethod: any) {
  const loginStore = useLoginStore()
  if (config.headers['Authorization'] !== 'Bearer ' + loginStore.authToken) {
    config.headers['Authorization'] = 'Bearer ' + loginStore.authToken
    if (returnType == 'client') {
      return httpClient(config)
    } else {
      return config
    }
  }
  const abortController = new AbortController()
  try {
    const token: any = jwt_decode(loginStore.authToken)
    const userId = token.UserID
    const abortController = new AbortController()
    const response = await httpClientAnonymous.post(
      '/web/api/Token/Refresh',
      userId,
      {
        headers: { 'api-version': '2' },
        signal: abortController.signal
      }
    )
    if (response.data?.success) {
      loginStore.setAuthTokenMethod(response.data.authToken)
      config.headers['Authorization'] = 'Bearer ' + response.data.authToken
      releaseMethod()
      processQueue(null, response.data.authToken)
      if (returnType == 'client') {
        return httpClient(config)
      } else return config
    } else {
      releaseMethod()
      // if no token, clear token, and reset store
      await loginStore.logout()
      const route = useRoute()
      router.push({
        name: 'login',
        query: { 'return-to': route?.path + route?.query }
      })
    }
  } catch (error) {
    abortController.abort()
    releaseMethod()
    processQueue(error, null)
    return error
  }
}

const handleError = async (err: any) => {
  const originalConfig = err.config

  if (axios.isCancel(err)) {
    return Promise.reject('Canceled')
  }
  if (err.code === 'ECONNABORTED') {
    return Promise.reject('Canceled')
  }

  if (originalConfig?.url !== '/web/api/Token/Login' && err?.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      if (mutex.isLocked()) {
        return queueRequest(originalConfig, 'client')
      }

      const release = await mutex.acquire()
      originalConfig._retry = true
      return await refreshToken(originalConfig, 'client', release)
    }
  }
  return Promise.reject(err)
}

// API Version 1

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: 240000,
  headers: {
    'Content-Type': 'application/json',
    AID: 'Web',
    'App-Version': import.meta.env.VITE_APP_VERSION,
    'Trace-User': '0'
  },
  withCredentials: true
})

httpClient.interceptors.response.use(
  async (res) => {
    return res
  },
  async (err) => {
    return handleError(err)
  }
)

httpClient.interceptors.request.use(async (config) => {
  return requestInterceptor(config)
})

export default httpClient

// to be used for calls to anonymous api endpoints, so we can bypass the interceptors
const httpClientAnonymous = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: 240000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    AID: 'Web',
    'App-Version': import.meta.env.VITE_APP_VERSION,
    'Trace-User': '0'
  }
})

export { httpClientAnonymous }

// Created for Safari's aggressive request deprioritizing/canceling or whatever
// Requests made while a system dialogue is open, e.g. the download confirmation popup are canceled
const httpClientRetry = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: 240000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    AID: 'Web',
    'App-Version': import.meta.env.VITE_APP_VERSION,
    'Trace-User': '0'
  }
})

const maxRetries = 5

httpClientRetry.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config

    if (!config || config.__retryCount >= maxRetries) {
      return Promise.reject(error)
    }

    if (
      !(error.code == 'ECONNABORTED') &&
      !error.message.includes('canceled')
    ) {
      return
    }

    config.__retryCount = config.__retryCount || 0
    config.__retryCount++

    const jitter = Math.random() * 500
    const delay = Math.pow(2, config.__retryCount) * 1000 + jitter

    await new Promise((resolve) => setTimeout(resolve, delay))

    return httpClientRetry.request(config)
  }
)

export { httpClientRetry }

// const httpClientV2Test = await httpClient.options('', {
//   timeout: 240000,
//   headers: {
//     'Content-Type': 'application/json',
//     AID: 'Web',
//     'api-version': '2',
//     'App-Version': import.meta.env.VITE_APP_VERSION,
//   }
// })
// export { httpClientV2Test }

// const httpClientWithCredentialsTest = httpClient.patch('', {
//   baseURL: import.meta.env.VITE_APP_API_BASE_URL,
//   timeout: 240000,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json',
//     AID: 'Web',
//     'App-Version': import.meta.env.VITE_APP_VERSION,
//   }
// })

// export { httpClientWithCredentialsTest }

// // API Version 2
// const httpClientV2 = axios.create({
//   baseURL: import.meta.env.VITE_APP_API_BASE_URL,
//   timeout: 240000,
//   headers: {
//     'Content-Type': 'application/json',
//     AID: 'Web',
//     'api-version': '2',
//     'App-Version': import.meta.env.VITE_APP_VERSION,
//   }
// })
// httpClientV2.interceptors.response.use(
//   async (res) => {
//     return res
//   },
//   async (err) => {
//     handleError(err)
//   }
// )

// httpClientV2.interceptors.request.use(async (config) => {
//   return requestInterceptor(config)
// })

// export { httpClientV2 }

// // API for Attachments

// const httpClientAttachments = axios.create({
//   baseURL: import.meta.env.VITE_APP_API_BASE_URL,
//   timeout: 0,
//   headers: {
//     'Content-Type': 'multipart/form-data',
//     AID: 'Web',
//     'App-Version': import.meta.env.VITE_APP_VERSION,
//   }
// })
// httpClientAttachments.interceptors.response.use(
//   async (res) => {
//     return res
//   },
//   async (err) => {
//     handleError(err)
//   }
// )
// httpClientAttachments.interceptors.request.use(async (config) => {
//   return requestInterceptor(config)
// })

// export { httpClientAttachments }

// const httpClientWithCredentials = axios.create({
//   baseURL: import.meta.env.VITE_APP_API_BASE_URL,
//   timeout: 240000,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json',
//     AID: 'Web',
//     'App-Version': import.meta.env.VITE_APP_VERSION,
//   }
// })

// httpClientWithCredentials.interceptors.request.use(async (config) => {
//   return requestInterceptor(config)
// })

// export { httpClientWithCredentials }
