﻿import { Frequency, RRule, rrulestr, Weekday, type ByWeekday } from 'rrule'
import type { ICalendarEntry, ICalendarEntryApi, IRRuleForm } from '@/models/interfaces'
import { DateTime, Interval } from 'luxon'

export default {
  isMultidayEventInDate(entry: ICalendarEntry, date: DateTime) {
    const _startDate = entry.startTime
    const _endDate = entry.endTime
    let isInDate = false
    if (_startDate.hasSame(date, 'day')) isInDate = true
    if (!isInDate && _endDate.hasSame(date, 'day')) isInDate = true
    if (Interval.fromDateTimes(_startDate, _endDate).contains(date)) isInDate = true

    return isInDate
  },
  isRecurringEventInDate(entry: ICalendarEntry, date: DateTime) {
    const rruleSet = rrulestr(entry.rRule)
    const recurringEventArray = rruleSet.between(
      date.startOf('day').toJSDate(),
      date.endOf('day').toJSDate()
    )
    return recurringEventArray.length > 0
  },
  getFirstOccurrence(rrule: string, startDate: Date) {
    //JS Date object - Sunday represents 0, Monday 1, etc.
    let startDateDayOfWeek = startDate.getDay() - 1
    if (startDateDayOfWeek < 0) startDateDayOfWeek = 7

    const rruleSet = rrulestr(rrule)

    //however, 0 here is Monday
    //null byweekday is replaced by the start date day of week to ensure the difference is zero
    //origOptions byweekday seems to always work well, structure is always { weekday: number, n?: number }
    const byWeekday = rruleSet.origOptions.byweekday as
      | Weekday
      | Weekday[]
      | null
      | undefined
    const latestDayOfWeek = Math.max(
      ...(Array.isArray(byWeekday)
        ? byWeekday.map((w) => w.weekday)
        : [byWeekday?.weekday ?? startDateDayOfWeek])
    )

    const dayOfWeekDifference = latestDayOfWeek - startDateDayOfWeek

    //getting the latest day of the week's date
    const latestRecurrenceDate = DateTime.fromJSDate(startDate, { zone: 'utc' })
      .plus({ day: dayOfWeekDifference })
      .toJSDate()

    //ensuring the "first occurrence" gotten from .after is the latest weekday
    //daily frequencies should have byweekday be null, other frequencies start with a non-null byweekday
    if (rruleSet.origOptions?.byweekday)
      rruleSet.origOptions.byweekday = [latestDayOfWeek]

    const firstOcc = rruleSet.after(latestRecurrenceDate, false)
    return firstOcc
  },
  constructRRule(
    freq: string,
    until: string | null,
    interval: number,
    byweekday: string[],
    bysetpos: number | null
  ) {
    const rrule = new RRule({
      freq: this.getRRuleFreq(freq),
      until: until
        ? new Date(until) // make sure this date is already converted to user's timezone then converted to utc and string passed has no offset
        : null,
      interval: interval,
      byweekday: freq == 'Daily' ? [] : this.getRRuleByDay(byweekday),
      bysetpos: bysetpos
    })

    //console.log('returning RRule string...: ', rrule.toString())

    return rrule.toString()
  },
  getBySetPos(bysetpos: string) {
    switch (bysetpos) {
      case 'day':
      case undefined:
      case null:
        return null
      case 'lastday':
        return -1
      default:
        return parseInt(bysetpos)
    }
  },
  constructRRuleForDatePicker(
    freq: string,
    interval: number,
    byweekday: string[],
    bysetpos: number | null,
    dtStart: DateTime
  ) {
    const rrule = new RRule({
      freq: this.getRRuleFreq(freq),
      interval: interval,
      byweekday: this.getRRuleByDay(byweekday),
      bysetpos: bysetpos,
      dtstart: dtStart.toJSDate()
    })

    //console.log('constructed rrule string: ', rrule.toString())
    return rrule.toString()
  },
  deconstructRRule(rruleString: string): IRRuleForm {
    // strip trailing ';'
    const _rrule = RRule.fromString(rruleString)
    const options = _rrule.origOptions

    const byweekday = options.byweekday as
      | Weekday
      | Weekday[]
      | null
      | undefined
    const rruleForm = {
      freq: this.getDeconstructedRRuleFreq(options.freq),
      until: options.until,
      interval: options.interval ? options.interval : 1,
      byweekday: this.getDeconstructedRRuleDay(options.byweekday),
      bysetpos: options.bysetpos ?? null,
      n: Array.isArray(byweekday) ? byweekday[0].n : byweekday?.n
    }

    return rruleForm
  },
  getRRuleFreq(val: string) {
    switch (val) {
      case 'Daily':
        return RRule.DAILY
      case 'Weekly':
      case 'Every Other Week':
        return RRule.WEEKLY
      case 'Monthly':
        return RRule.MONTHLY
    }
  },
  getDeconstructedRRuleFreq(val: Frequency | undefined): string {
    let _text = ''
    switch (val) {
      case RRule.DAILY:
        _text = 'Daily'
        break
      case RRule.WEEKLY:
        _text = 'Weekly'
        break
      case RRule.MONTHLY:
        _text = 'Monthly'
        break
    }
    return _text
  },
  getDeconstructedRRuleDay(vals: any) {
    if (vals) {
      const rule = vals.map(this.deconstructDayValue)
      return rule
    }
    return null
  },
  deconstructDayValue(val: Weekday) {
    //Weekday has two relevant attributes:
    //weekday: (0-6, 0 is monday)
    //n: number, for our purposes, -1 for last weekday, 1 for first weekday, 2 second, etc.; can be undefined

    switch (val.weekday) {
      case 0:
        return 'monday'
      case 1:
        return 'tuesday'
      case 2:
        return 'wednesday'
      case 3:
        return 'thursday'
      case 4:
        return 'friday'
      case 5:
        return 'saturday'
      case 6:
        return 'sunday'
    }
  },
  deconstructBySetPosValue(val: string | number | number[]) {
    switch (val) {
      case 1:
        return 'first'
      case 2:
        return 'second'
      case 3:
        return 'third'
      case 4:
        return 'fourth'
      case -1:
        return 'last'
      default:
        return 'each'
    }
  },
  getRRuleByDay(val: string[]): ByWeekday | ByWeekday[] | null | undefined {
    if (val) {
      const rule = val.map(this.getRRuleDayValue)
      return rule
    }
    return null
  },
  getRRuleDayValue(val: string): Weekday {
    let _weekday: Weekday

    let finalVal = val
    let n = parseInt(val) as number | null

    if (n && isNaN(n)) {
      n = null
    } else {
      finalVal = val.replace(`${n}`, '')
    }

    switch (finalVal) {
      case 'MO':
        _weekday = new Weekday(0, n ?? undefined)
        break
      case 'TU':
        _weekday = new Weekday(1, n ?? undefined)
        break
      case 'WE':
        _weekday = new Weekday(2, n ?? undefined)
        break
      case 'TH':
        _weekday = new Weekday(3, n ?? undefined)
        break
      case 'FR':
        _weekday = new Weekday(4, n ?? undefined)
        break
      case 'SA':
        _weekday = new Weekday(5, n ?? undefined)
        break
      case 'SU':
        _weekday = new Weekday(6, n ?? undefined)
        break
      default:
        _weekday = new Weekday(6, n ?? undefined)
    }
    return _weekday
  },
  getRRuleAsText(rruleString: string, startTime: DateTime, timeZoneId?: string) {
    if (rruleString && rruleString.length > 0) {
      const _rruleString = rruleString.replace(/;\s*$/, '')
      const ruleObj = this.deconstructRRule(_rruleString)
      switch (ruleObj.freq) {
        case 'Monthly':
          return this.createMonthlyText(_rruleString, startTime, timeZoneId ?? 'utc')
        case 'Weekly':
          return this.createWeeklyText(_rruleString, startTime, timeZoneId)
        case 'Daily':
          return rrulestr(_rruleString, { tzid: timeZoneId }).toText()
      }
    }
    return ''
  },
  createWeeklyText(rruleString: string, startTime: DateTime, timeZoneId?: string) {
    //console.log('createWeeklyText rruleString: ', rruleString)

    let rruleObj = rrulestr(rruleString, { tzid: timeZoneId })

    let rrule = rruleObj.toText()

    const pos = rrule.lastIndexOf('day, ')
    //console.log(`last index of 'day, ': `, pos)
    if (pos >= 0) {
      rrule = rrule.substring(0, pos + 3) + ' and ' + rrule.substring(pos + 5)
    }

    //console.log(rrule)

    //for single-day events that repeat every day every x weeks, where x > 1
    const grammarPos = rrule.lastIndexOf('weeks days')
    //console.log(`last index of 'weeks days': `, grammarPos)
    if (grammarPos >= 0) {
      rrule =
        rrule.substring(0, grammarPos) +
        'weeks every day' +
        rrule.substring(grammarPos + 10)
    }

    const untilPos = rrule.lastIndexOf('until')
    if (untilPos >= 0) {
      rrule =
        rrule.substring(0, untilPos) +
        ` starting on ${startTime.toFormat('MMMM d, yyyy')} ` +
        rrule.substring(untilPos)
    } else {
      rrule += ` starting on ${startTime.toFormat('MMMM d, yyyy')}`
    }

    //console.log('finally: ', rrule)
    //rrule += ` starting on `
    return rrule
  },
  createMonthlyText(rruleString: string, startTime: DateTime, timezone: string) {
    //console.log('rruleString: ', rruleString)

    let _text = ' every'
    const ruleObj = this.deconstructRRule(rruleString)

    //console.log('ruleObj: ', ruleObj)

    // handly repeats every selection
    if (ruleObj.interval == 1) {
      _text += ' month'
    } else {
      _text += ` ${ruleObj.interval} months`
    }
    const recurringOnLastDayOfMonth =
      ruleObj.bysetpos == -1 &&
      ruleObj.byweekday &&
      ruleObj.byweekday.length == 7

    //console.log('recurringOnLastDayOfMonth: ', recurringOnLastDayOfMonth)

    if (recurringOnLastDayOfMonth) {
      _text += ' on the last day of the month'
    } else {
      // if there is an "on" selection
      if (ruleObj.n != undefined && ruleObj.n != null) {
        //console.log('bysetpos is not undefined nor null')

        //fine to use first byweekday for this text
        //we don't currently allow mixing and matching of weekday multiples, e.g. first wednesday and second thursday of each month
        _text += ` on the ${this.deconstructBySetPosValue(ruleObj.n)}`
      }

      //console.log('ruleObj.byweekday.length: ', ruleObj.byweekday?.length)

      // if there are day selections
      if (ruleObj.byweekday?.length) {
        const _days = this.parseByWeekday(ruleObj.byweekday)
        const _daysText = _days ? _days : ''
        _text +=
          ruleObj.n == undefined || ruleObj.n == null
            ? ` on each ${_daysText}`
            : ` ${this.parseByWeekday(ruleObj.byweekday)}`
      } else {
        _text += ` on the ${this.getDayOfMonthFormattedOrdinal(startTime.day)}`
      }
    }

    const untilStartPos = rruleString.indexOf('UNTIL=', 0)

    if (untilStartPos >= 0) {
      const untilEndPos =
        rruleString.indexOf(';', untilStartPos) > -1
          ? rruleString.indexOf(';', untilStartPos)
          : rruleString.length
      const untilDate = rruleString.substring(untilStartPos + 6, untilEndPos) // 20201129T050000Z
      const untilDateObj = DateTime.fromISO(untilDate, { zone: timezone })
      _text =
        _text +
        ` starting ${recurringOnLastDayOfMonth ? 'in' : 'on'} ${startTime.toFormat(
          recurringOnLastDayOfMonth ? 'MMMM' : 'MMMM d, yyyy'
        )} until ` +
        untilDateObj.toFormat('MMMM d, yyyy')
    } else {
      _text += ` starting ${recurringOnLastDayOfMonth ? 'in' : 'on'} ${startTime.toFormat(
        recurringOnLastDayOfMonth ? 'MMMM' : 'MMMM d, yyyy'
      )}`
    }

    return _text
  },
  parseByWeekday(byweekday: any) {
    if (byweekday.length == 1) {
      return this.capitalize(byweekday[0])
    } else {
      const _byweekday = byweekday.map((d: string) => this.capitalize(d))
      let _days = _byweekday.join(', ')
      const pos = _days.lastIndexOf(', ')
      _days = _days.substring(0, pos) + ' and ' + _days.substring(pos + 1)
      return _days
    }
  },
  getDayOfMonthFormattedOrdinal(dayOfMonth: number) {
    if (dayOfMonth === 11 || dayOfMonth === 12 || dayOfMonth === 13) return dayOfMonth.toString()+'th'
  
    let lastDigit = dayOfMonth.toString().slice(-1)
    switch (lastDigit) {
      case '1': return dayOfMonth.toString()+'st'
      case '2': return dayOfMonth.toString()+'nd'
      case '3': return dayOfMonth.toString()+'rd'
      default:  return dayOfMonth.toString()+'th'
    }
  },
  capitalize(day: any) {
    if (typeof day !== 'string') return ''
    return day.charAt(0).toUpperCase() + day.slice(1)
  },
  getMonthStartDate(year: number, month: number) {
    return DateTime.fromISO(`${year}-${month}-01`).startOf('week', { useLocaleWeeks: true })
  },
  getMonthEndDate(year: number, month: number) {
    return DateTime.fromISO(`${year}-${month}-01`).endOf('month').endOf('week', { useLocaleWeeks: true })
  },
  getEventsForDate(dateInUserTz: DateTime, events: ICalendarEntry[]) {
    // events for the date provided
    const _events = []
    for (let i = 0; i < events.length; i++) {
      let isInDate = false
      //check if event is all day, if so check against utc time
      if (events[i].isAllDay) {
        // if (events[i].StartTime.format('YYYY-MM-DD') == date) {
        //     isInDate = true
        // }
        if (events[i].startTime.hasSame(dateInUserTz, 'day')) {
          isInDate = true
        }
      } else {
        // regular event
        if (events[i].startTime.hasSame(dateInUserTz, 'day')) {
          isInDate = true
        }
        // multiday event
        if (
          !isInDate &&
          events[i].endTime.hasSame(dateInUserTz, 'day')
        ) {
          isInDate = true
        }
        // multiday event
        if (
          !isInDate &&
          Interval
            .fromDateTimes(events[i].startTime, events[i].endTime)
            .contains(dateInUserTz)
        )
          isInDate = true
      }
      if (isInDate) _events.push(events[i])
    }
    return _events
  },
  convertEventDatesToUsersLocalTime(
    events: ICalendarEntryApi[],
    timezone: string
  ): ICalendarEntry[] {
    const _entries: ICalendarEntry[] = JSON.parse(JSON.stringify(events))

    for (let i = 0; i < events.length; i++) {
      _entries[i].startTime = events[i].isAllDay
        ? DateTime.fromISO(events[i].startTime, { zone: 'utc'})
        : DateTime.fromISO(events[i].startTime, { zone: 'utc'}).setZone(timezone)
      _entries[i].endTime = events[i].isAllDay
        ? DateTime.fromISO(events[i].endTime, { zone: 'utc'})
        : DateTime.fromISO(events[i].endTime, { zone: 'utc'}).setZone(timezone)
      _entries[i].dtStart = events[i].dtStart
        ? events[i].isAllDay
          ? DateTime.fromISO(events[i].dtStart ?? '', { zone: 'utc'})
          : DateTime.fromISO(events[i].dtStart ?? '', { zone: 'utc'}).setZone(timezone)
        : null
      _entries[i].until = events[i].until
        ? DateTime.fromISO(events[i].until ?? '', { zone: 'utc'}).setZone(timezone)
        : null
      _entries[i].createdWhen = events[i].createdWhen
        ? DateTime.fromISO(events[i].createdWhen ?? '', { zone: 'utc'}).setZone(timezone)
        : null
      _entries[i].lastViewed = events[i].lastViewed
        ? DateTime.fromISO(events[i].lastViewed ?? '', { zone: 'utc'}).setZone(timezone)
        : null
      _entries[i].lastEdited = events[i].lastEdited
        ? DateTime.fromISO(events[i].lastEdited ?? '', { zone: 'utc'}).setZone(timezone)
        : null
    }
    return _entries
  },
  convertEventToUsersLocalTime(
    event: ICalendarEntryApi,
    timezone: string
  ): ICalendarEntry {
    const convertedEntry:ICalendarEntry = JSON.parse(JSON.stringify(event))
    return {
      ...convertedEntry,
      startTime: event.isAllDay
        ? DateTime.fromISO(event.startTime as string, { zone: 'utc'}).startOf('day')
        : DateTime.fromISO(event.startTime as string, { zone: 'utc'}).setZone(timezone),
      endTime: event.isAllDay
        ? DateTime.fromISO(event.endTime as string, { zone: 'utc'})
        : DateTime.fromISO(event.endTime as string, { zone: 'utc'}).setZone(timezone),
      dtStart: event.dtStart && event.dtStart.length > 0
        ? event.isAllDay
          ? DateTime.fromISO(event.dtStart ?? '', { zone: 'utc'})
          : DateTime.fromISO(event.dtStart ?? '', { zone: 'utc'}).setZone(timezone)
        : null,
      until: event.until && event.until.length > 0
        ? DateTime.fromISO(event.until, { zone: 'utc'}).setZone(timezone)
        : null,
      lastEdited: event.lastEdited
        ? DateTime.fromISO(event.lastEdited, { zone: 'utc'}).setZone(timezone)
        : null,
      lastViewed: event.lastViewed
        ? DateTime.fromISO(event.lastViewed, { zone: 'utc'}).setZone(timezone)
        : null,
      createdWhen: event.createdWhen
        ? DateTime.fromISO(event.createdWhen, { zone: 'utc'}).setZone(timezone)
        : null
    }
  },
  isDayInMonth(dayString: string, currentDay: DateTime) {
    return DateTime.fromISO(dayString).hasSame(currentDay, 'month')
  },
  isEventMultiDay(event: ICalendarEntry) { // done
    return this.isMultiDay(event) && !event.isAllDay && !event.isNew
  },
  isEventSingleDay(event: ICalendarEntry) { // done
    return this.isSingleDay(event) && !event.isAllDay && !event.isNew
  },
  isEventGreaterThanWeek(event: ICalendarEntry) {
    return (
      event.endTime.diff(event.startTime, 'days').days >= 7 &&
      !event.isAllDay &&
      !event.isNew
    )
  },
  isEventGreaterThanMonth(event: ICalendarEntry) {
    return (
      event.endTime == null ||
      event.startTime == null ||
      (event.endTime.diff(event.startTime, 'days').days > 27 &&
        !event.isAllDay &&
        !event.isNew)
    )
  },
  isEventForHourlyGrouping(event: ICalendarEntry, currentDay: DateTime) { // done
    return (
      this.isEventSingleDay(event) ||
      (this.isEventMultiDay(event) &&
        (event.startTime.hasSame(currentDay, 'day') ||
          event.endTime.hasSame(currentDay, 'day')
        )
      )
    )
  },
  isEventAllDay(event: ICalendarEntry, currentDay: DateTime) { // done
    return (
      event.isAllDay ||
      (this.isEventMultiDay(event) &&
        event.startTime.day < currentDay.day &&
        event.endTime.day != currentDay.day
      )
    )
  },
  isEventInDay(event: ICalendarEntry, day: string, timezone: string) {
    // if recurring, make sure the start date is the same as the request date
    if (event.isRecurring) {
      const startTime = event.isAllDay
        ? event.startTime.toUTC()
        : event.startTime.setZone(timezone)
      return startTime.day == DateTime.fromISO(day, { zone: 'utc'}).day
    }
    return true
  },
  isEventUnseen(event: ICalendarEntry) { // done
    return event.isNew
  },
  isMultiDay(event: ICalendarEntry) { // done
    return !event.endTime.hasSame(event.startTime, 'day') && !event.isAllDay
  },
  isSingleDay(event: ICalendarEntry) {
    return event.endTime.hasSame(event.startTime, 'day')
  },
  // gets the first day that shows up on month calendar (in specific timezone)
  getStartDateFromParamsForApiCall(dateTime: DateTime, timezone?: string): DateTime { //
    const startDate = dateTime.setZone(timezone ?? 'utc').startOf('month').startOf('week', { useLocaleWeeks: true }).startOf('day')
    return startDate.toUTC()
  },
  // gets the last day that shows up on month calendar (in specific timezone)
  getEndDateFromParamsForApiCall(dateTime: DateTime, timezone?: string): DateTime { //
    const endDate = dateTime.setZone(timezone ?? 'utc').endOf('month').endOf('week', { useLocaleWeeks: true }).endOf('day')
    return endDate.toUTC()
  },
  getLastOccurrenceBeforeUntil(rrule: string, start: DateTime, until: DateTime) {
    const rruleObj = rrulestr(rrule, { dtstart: start.toJSDate() })

    return rruleObj.before(until.toJSDate(), true)
  }
}
