import { toast, type ToastType } from 'vue3-toastify'
import EmptyToast from '@/components/library/EmptyToast.vue'
import { h } from 'vue'

export default {
  toast(
    type: ToastType,
    text: string,
    showCloseButton?: boolean,
    icon?: string,
    iconClass?: string
  ) {
    toast(
      h(EmptyToast, {
        text: text,
        showCloseButton: showCloseButton,
        icon: icon,
        iconClass: iconClass
      }),
      {
        type: type
      }
    )
  },
  infoToast(
    text: string,
    showCloseButton?: boolean,
    icon?: string,
    iconClass?: string
  ) {
    toast.info(
      h(EmptyToast, {
        text: text,
        showCloseButton: showCloseButton,
        icon: icon,
        iconClass: iconClass
      })
    )
  },
  errorToast(
    text: string,
    showCloseButton?: boolean,
    icon?: string,
    iconClass?: string
  ) {
    toast.error(
      h(EmptyToast, {
        text: text,
        showCloseButton: showCloseButton,
        icon: icon,
        iconClass: iconClass
      })
    )
  },
  warningToast(
    text: string,
    showCloseButton?: boolean,
    icon?: string,
    iconClass?: string
  ) {
    toast.warning(
      h(EmptyToast, {
        text: text,
        showCloseButton: showCloseButton,
        icon: icon,
        iconClass: iconClass
      })
    )
  },
  expressToast(text: string, showCloseButton?: boolean) {
    this.infoToast(text, showCloseButton, 'fa-regular fa-circle-bolt')
  }
}
