<template>
  <MainContentComponent
    :layout-class="getLayoutSize"
    :padding="hasPadding"
  >
    <router-view class="messages-container" />
  </MainContentComponent>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { useMessagesStore } from '@/stores/MessagesStore'
import MainContentComponent from './components/MainContentComponent.vue'
import { computed, onBeforeMount, onUnmounted, watch } from 'vue'
import constants from '@/exports/constants'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const commonStore = useCommonStore()
const {
  showingNoCount,
  welcomePagesViewed,
  layoutCommonDataLoaded,
  fullUserInfo,
  featureFlags,
  isMobileWidth
} = storeToRefs(commonStore)
const { setShowAddNewButtonLayoutOption, setLayoutOptions, setPageHeaders } =
  commonStore

const messagesStore = useMessagesStore()
const {
  setThreadListSearchTerm,
  setThreadListPage,
  setThreadListScrollPosition,
} = messagesStore

onBeforeMount(() => {
  if (layoutCommonDataLoaded.value) {
    init()
  }
})

watch(layoutCommonDataLoaded, (val) => {
  if (val) {
    init()
  }
})

function init() {
  setLayoutOptions({
    pageType: constants.pageType.list,
    appLayoutHeightOfViewport:
      welcomePagesViewed.value.messages || !showingNoCount.value,
  })

  setPageHeaders({
    page: 'Messages',
    pageName: fullUserInfo.value.coparentFullName
      ? `${t('titles.messaging', { name: fullUserInfo.value.coparentFullName })}`
      : t('titles.messagingMobile'),
    pageNameMobile: fullUserInfo.value.coparentFullName
      ? `${fullUserInfo.value.coparentFullName}`
      : t('titles.messagingMobile'),
    pageIcon: 'fak fa-messages',
    searchEnabled: true,
    pageData: {},
    searching: true,
    welcomePageName: 'messagesWelcome'
  })
}

onUnmounted(() => {
  setThreadListSearchTerm('')
  setThreadListPage(1)
  setThreadListScrollPosition(0)
})

watch(
  () => welcomePagesViewed.value.messages,
  (val) => {
    if (!showingNoCount.value) {
      return
    }

    setShowAddNewButtonLayoutOption(val)
  }
)

const getLayoutSize = computed(() => 
  showingNoCount.value && !welcomePagesViewed.value.messages
    ? 'layout-inner-sm'
    : featureFlags.value?.web_messages_enable3ColumnLayout
      ? 'layout-full'
      : 'layout-md'
  )

const hasPadding = computed(() => !featureFlags.value?.web_messages_enable3ColumnLayout && !isMobileWidth.value)
</script>

<style scoped>
.messages-container {
  height: 100%;
}

.content-wrap {
  min-height: 0;
}
</style>
