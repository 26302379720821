<template>
  <div id="auth" class="login">
    <!-- header and content -->
    <div class="center-login">
      <header class="header">
        <a :href="getWebsiteBaseUrl" class="logo-link" title="TalkingParents">
          <TPLogo class="logo" :white-logo="isDesktopWidth" />
        </a>
      </header>
      <main id="main" class="main">
        <div id="login" class="login-container">
          <router-view />
        </div>
      </main>
    </div>
    <!-- footer links -->
    <footer class="footer">
      <a
        v-if="isDesktopWidth"
        :class="{ 'text-center pb-1': !isDesktopWidth }"
        target="_blank"
        href="https://support.talkingparents.com/hc/en-us/requests/new"
      >
        <font-awesome-icon class="icon-size-1" icon="fa-light fa-envelope" />
        Contact us
      </a>
      <div class="footer-info">
        <small>
          <span :class="{ 'font-size-00': !isDesktopWidth }">
            &copy; 2012-{{ state.year }} Monitored Communications, LLC.
            {{ isDesktopWidth ? '|' : '' }}
          </span>
          <div class="flex gap-0 mx-auto">
            <a
              class="underline"
              :class="isDesktopWidth ? '' : 'font-size-00'"
              :href="getWebsiteBaseUrl + 'terms-of-service'"
              target="_blank"
            >
              Terms of Service
            </a>
            |
            <a
              class="underline"
              :class="{ 'font-size-00': !isDesktopWidth }"
              :href="getWebsiteBaseUrl + 'privacy-policy'"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </small>
        <small class="app-version"> v{{ appVersion }} </small>
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onUnmounted, reactive } from 'vue'
import { DateTime } from 'luxon'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { useDebounceFn, useColorMode } from '@vueuse/core'
import TPLogo from '@/components/TPLogo.vue'
const commonStore = useCommonStore()
const { setWindowWidthChanges } = commonStore
const { getWebsiteBaseUrl, isDesktopWidth } = storeToRefs(commonStore)
useColorMode({ attribute: 'color-scheme' })

const state = reactive({
  year: DateTime.now().toFormat('yyyy')
})

const appVersion = computed(() => {
  return import.meta.env.VITE_APP_VERSION
})

onBeforeMount(() => {
  document.title = 'Sign In'
  const _metaDesc = document.head.querySelector('meta[name=description]')
  _metaDesc &&
    _metaDesc.setAttribute(
      'content',
      'Sign in to TalkingParents or create your free account now'
    )

  document.head
    .querySelector('meta[name=robots]')
    ?.setAttribute('content', 'index')
  window.addEventListener('resize', handleResize)
  handleResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

const debouncedFn = useDebounceFn(
  () => {
    setWindowWidthChanges()
  },
  500,
  { maxWait: 1000 }
)

async function handleResize() {
  debouncedFn()
}
</script>

<style lang="scss" scoped>
/* Layout */
#auth {
  padding-top: 4rem;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (width >= 48em) {
    background: light-dark(
      linear-gradient(45deg, #58628c 20%, #377088 100%),
      linear-gradient(45deg, #282c3f 20%, #09373a 100%)
    );
    justify-content: center;
  }
}

/* Header / Logo */
header {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.logo-link {
  display: flex;
  margin-bottom: 1.75rem;
}
.logo {
  height: 40px;
  width: 175px;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    margin-bottom: 0;
    height: 50px;
  }
}
/* Form */
.center-login {
  display: flex;
  flex-direction: column;

  @media (min-width: 48em) {
    margin-top: auto;
  }
}

.login-container {
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--radius-1);
  background-color: var(--surface-1);
  width: 100%;
  padding: 1.5rem;

  @media (min-width: 48em) {
    padding: 2rem;
    width: 28rem;
  }
}

/* Footer */
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--brand);
  margin-top: auto;

  @media (min-width: 48em) {
    padding-top: 1rem;
    color: var(--white);
  }
}

small {
  display: flex;
  font-size: 0.75rem;
  padding: 1rem;
  gap: var(--size-00);

  flex-direction: column;
  @media (min-width: 48em) {
    flex-direction: row;
  }
}

footer a {
  text-decoration-color: transparent;
  transition: all 0.2s ease;
  color: var(--brand);
  @media (min-width: 48em) {
    color: var(--white);
  }
}

.footer-info {
  display: grid;
  width: 100%;
  grid-template-columns: none;
  grid-template-rows: 1fr 0.25fr;
  @media (min-width: 48em) {
    grid-template-rows: none;
    grid-template-columns: 0.25fr 1fr 0.25fr;
  }
}
.footer-info > small {
  &:first-child {
    grid-column-start: 1;
    justify-self: center;

    @media (min-width: 48em) {
      grid-column-start: 2;
    }
  }

  justify-self: center;
  @media (min-width: 48em) {
    justify-self: end;
  }
}

.app-version {
  opacity: 50%;
  padding-top: 0;
  @media (min-width: 48em) {
    padding-top: 1rem;
  }
}
</style>
