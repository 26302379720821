import { DateTime } from 'luxon'
import type {
  ICreatePendingSignupResult,
  IVerifyPendingSignupResult,
  IResendVerifyCodeResult,
  IRecordOrder,
  IRecordOrderItem,
  MoneyTransferStatus,
  Subscription,
  BooleanApiResult,
  ICreateAccountResult,
  IValidateMatchCodeResult,
  IValidateEmailMatchResult,
  IUpdateMatchInformationResult,
  IEnableMfaSmsResult,
  IDisableMfaSmsResult,
  IDeleteUnmatchedAccountResult,
  ISendMatchCodeResult,
  IUpdateFundingSourceDependenciesRequest,
  IMoneyTransferIdRequest,
  ITimeZoneInfo,
  MessagingThreadItem,
  IMessagingThread,
  Money,
  MoneyTransferDocumentType,
  MoneyTransferDocumentStatus,
  MoneyTransferDocumentFailureStatus,
  MoneyTransferCustomerStatus,
  MoneyTransferNegativeBalanceType,
  MoneyTransferCustomerVerificationStatus,
  MoneyTransferBankAccountType,
  MoneyTransferFundingSourceStatus,
  MoneyTransferMicroDepositStatus,
  MoneyTransferAVStatus,
  MoneyTransferRequestStatus,
  MoneyTransferFeeType,
  MoneyTransferScheduledPaymentStatus,
  MoneyTransferClearingType
} from './models'
import type { CallType, VideoCallAction, VideoCallStatus } from './modelsV3'
import type {
  CallingCallDirection,
  CallingCallType,
  VaultThumbnailStatus,
  SortFilterFieldsetTypes,
  DownloadStatus,
  OwnerFilter,
  FileType,
  SortOption,
  MessagesFilterByType,
  FinalCallingStatus,
  ConversationItemType,
  GetPagedThreadResultError
} from './enums'
import type {
  GlobalSearchResultType,
  MessagesSortFilterDateOptions,
  MessagesSortFilterSentOptions,
  MessagesSortFilterSortOptions
} from './enums.ts'

export interface PageHeader {
  page: string
  pageName: string
  pageNameMobile: string
  pageIcon: string
  searchEnabled: boolean
  searching?: boolean
  pageData?: unknown
  welcomePageName?: string
  pageBreadcrumbs?: IBreadcrumbEvent[]
}

export interface IMainPageContent {
  enabled?: boolean
  largeText?: string
  largeHighlight?: string
  largeWarning?: string
  pText?: string
}

export interface IDateTimeSettings {
  dateFormatID?: number
  formatName?: string
  shortDateFormat: string
  timeZoneID?: number
  timeZoneIdentifier?: string
  timeZoneNameForMomentJs: string
  userID?: number
}

export interface IPhoneInfo {
  allowCallsFromMatchedUser?: boolean
  allowsVideoCalls?: boolean
  isUserMobilePhoneVerified?: boolean
  userMobilePhone?: string
  userMobilePhoneCarrierName?: string
  userMobilePhoneCarrierType?: string
}

export interface IDateFormats {
  formatName: string
  itemID: number
  shortDateFormat: string
}

export interface ICustomer {
  cards?: Array<ICreditCard>
  customerID?: string
}

export interface ICreditCard {
  CardType?: string
  Expiration?: string
  IsDefault?: boolean
  IsExpired?: boolean
  IsSmsCard?: boolean
  IsSubscription?: boolean
  LastFour?: string
  Token?: string
}

export interface IPlan {
  appleProductID?: string
  disabled?: boolean
  itemID?: number
  minutes?: number
  price?: number
  priceApple?: number
}

export interface ITimeZone {
  displayName?: string
  enabled?: boolean
  identifier?: string
  itemID?: number
}

export interface IApiResponse {
  config: any
  date: any
  headers: any
  request: any
  status: number
  statusText: string
}

export interface IUpdateCreditCard {
  userId: number
  cardToken: string
  expDate: string
  cvv: string
  verify?: boolean
}

export interface IGetAttachmentForDownloadRequest {
  specifyFileName: string
  itemID: number
  threadID: number
  isFromLibrary: boolean
}

export interface ILoginRequest {
  username?: string
  password?: string
  returnUrl?: string
  mfaCode?: string
}

export interface IWebToggleLoginRequest {
  webToggleToken: string | undefined
}

export interface IUpdatePasswordRequest {
  newPassword: string | undefined
  email: string | undefined
  requestCode: string | undefined
  recaptchaToken: string | undefined
}

export interface ISubmitError {
  error?: boolean
  message?: string
}

export interface IVerifyCodeResult {
  valid: boolean
  changed: boolean
  value: string
}

export interface IEnterInviteCodeProps {
  wizard: boolean
  show: boolean
}

export interface IGenericResult {
  valid: boolean
  changed: boolean
  value: string
}

export interface IUserSubscription {
  isFreeTrialEligible?: boolean | undefined
  duplicateSubscriptionError?: number | undefined
  highestTier?: number | undefined
  hasSubscription?: boolean | undefined
  activeSubscriptions?: Subscription[] | undefined
  suspendedSubscriptions?: Subscription[] | undefined
}

export interface IGetJournalEntriesRequest {
  page: number
  searchTerm: string
}

export interface IGetAttachmentsRequest {
  page: number
  searchTerm: string
}

export interface ICreateOrUpdateJournalRequest {
  title: string
  message: string
}

export interface IDownloadAttachmentRequest {
  attachmentId: number
  fileName: string
}

export interface IHeaderItems {
  displayName: string
  propertyName: string
  headerClass: string
  itemClass: string
}

export interface IDropzoneOptions {
  paramName: string // The name that will be used to transfer the file
  maxFilesize: number // MB
  url: string
  uploadMultiple: boolean
  maxFiles: number
  autoProcessQueue: boolean
  addRemoveLinks: boolean
}

export interface IReturnObj {
  Success?: boolean | undefined
  ErrorCode?: number | undefined
  ErrorMessage?: string | undefined
  Value?: any | undefined
}

export interface IGetAttachmentForDownloadPayload {
  specifyFileName: string
  isFromLibrary: boolean | null | undefined
  itemID: number | null | undefined
  threadID: number | null | undefined
  api_version: string | null | undefined
}

export interface IPageHeader {
  page: string
  pageName: string
  pageNameMobile: string
  pageIcon: string
  searchEnabled: boolean
  searching: boolean
  pageData: any
  welcomePageName?: string
  pageBreadcrumbs?: IBreadcrumbEvent[]
}

export interface INewReplyAlert {
  newReply: boolean
  threadId: number
}

export interface IPagedPayload {
  page: number
}

export interface IFetchActivityPagedPayload {
  page: number
  sortDescending?: boolean
  startDate?: DateTime
  endDate?: DateTime
  filterType?: number
  searchTerm?: string
}

export interface IFetchActivityByIdPayload {
  id: number
  type: number
}

export interface IActivityByIdPayload {
  requestIds?: number[] | number | null | undefined
  scheduledPaymentIds?: number[] | number | null | undefined
}

export interface IPagedSearchPayload {
  page: number
  searchTerm: string
}

export interface IIdPayload {
  id: number
}

export interface IPagedThreadPayload {
  threadID: number
  mostRecent?: string
  pageSize?: number
}

export interface ILatestThreadsPayload {
  threadID: number
  mostRecent: string | undefined
  itemID: number
}

export interface IReplyAlert {
  newReply: boolean
  threadId: number
  senderId?: number
}

export interface IThreadAlert {
  newThread: boolean
  threadId: number
}

export interface ICardAlert {
  newCard: boolean
  cardId: number
}

export interface IShippingAddress {
  shippingName?: string
  shippingAddress?: string
  shippingAddressLineTwo?: string
  shippingCity?: string
  shippingState?: string
  shippingZip?: string
}

export interface ISnackbar {
  duration: number
  position: string
  text: string
  showBtn: boolean
  show: boolean
  lastEntryId: number
}

export interface IRecord {
  key: string
  type: number
  title: string
  icon: string
  description: IRecordDescription
  showIncludeTranscript: boolean
  isSelectedPrintedRecords: boolean
  isSelectedPdfRecords: boolean
  signedOption: boolean
  includeTranscripts: boolean
  jobId: number
  pageCount: number
  printedCost: number
  orderNum: number
  isAvailable: boolean
  unavailableMessage: string
  reportBlobName?: string
  fetchingPages?: boolean
}

export interface IRecordDescription {
  details: IRecordDescriptionDetail[]
}

export interface IRecordDescriptionDetail {
  content: string
  tier: number
}

export interface IRequestPageCountPayload {
  signed: boolean
  includeTranscripts: boolean | null | undefined
  reportType: number | null | undefined
  api_version: string | null | undefined
}

export interface IStartPurchaseRecordsPayload {
  recordOrder: IRecordOrder
  recordOrderItems: IRecordOrderItem[]
}
export interface IRecordDto {
  ict: boolean
  iso: boolean
  type: number
  JobId: number
}

export interface IFormObj {
  validationType: string
  touched: boolean
  value: string
}

export interface IShippingForm {
  fullName: IFormObj
  address: IFormObj
  addressCont: IFormObj
  city: IFormObj
  state: IFormObj
  zip: IFormObj
}

export interface IMessageForm {
  message: IFormObj
  subject: IFormObj
}
export interface IMessage {
  message: string
  subject: string
}

export interface ICalendarInfo {
  initials: string
  otherInitials: string
  otherFullName: string
  timeZone: any
  caseId: number
  userDateFormat: string
}

export class CalendarEntry implements ICalendarEntry {
  itemID: number
  subject: string
  notes: string
  startTime: DateTime
  endTime: DateTime
  isRecurring: boolean
  isAllDay: boolean
  isNew: boolean
  color: string
  rRule: string
  dtStart: DateTime | null
  until: DateTime | null
  ownerID: number
  parentItemID?: number | null
  lastEdited?: DateTime | null
  lastViewed?: DateTime | null
  createdWhen?: DateTime | null
  isCanceled?: boolean

  constructor(
    placeholder?: { subject: string; isAllDay: boolean; color: string },
    data?: ICalendarEntry
  ) {
    ;(this.itemID = 0),
      (this.subject = ''),
      (this.notes = ''),
      (this.startTime = DateTime.local()),
      (this.endTime = DateTime.local()),
      (this.isRecurring = false),
      (this.isAllDay = false),
      (this.isNew = false),
      (this.color = 'default-color'),
      (this.rRule = ''),
      (this.dtStart = null),
      (this.until = null),
      (this.ownerID = 0),
      (this.parentItemID = null),
      (this.lastEdited = null),
      (this.lastViewed = null),
      (this.createdWhen = null),
      (this.isCanceled = false)

    Object.assign(this, placeholder)

    if (data) {
      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.itemID = _data['itemID']
      this.subject = _data['subject']
      this.notes = _data['notes']
      this.startTime = _data['startTime']
        ? new Date(_data['startTime'].toString())
        : <any>undefined
      this.endTime = _data['endTime']
        ? new Date(_data['endTime'].toString())
        : <any>undefined
      this.isRecurring = _data['isRecurring']
      this.isAllDay = _data['isAllDay']
      this.isNew = _data['isNew']
      this.color = _data['color']
      this.rRule = _data['rRule']
      this.dtStart = _data['dtStart']
        ? new Date(_data['dtStart'].toString())
        : <any>undefined
      this.until = _data['until']
        ? new Date(_data['until'].toString())
        : <any>undefined
      this.ownerID = _data['ownerID']
      this.parentItemID = _data['parentItemID']
      this.lastEdited = _data['lastEdited']
        ? new Date(_data['lastEdited'].toString())
        : <any>undefined
      this.lastViewed = _data['lastViewed']
        ? new Date(_data['lastViewed'].toString())
        : <any>undefined
      this.createdWhen = _data['createdWhen']
        ? new Date(_data['createdWhen'].toString())
        : <any>undefined
      this.isCanceled = _data['isCanceled']
    }
  }

  static fromJS(data: any): CalendarEntry {
    data = typeof data === 'object' ? data : {}
    const result = new CalendarEntry()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['itemID'] = this.itemID
    data['subject'] = this.subject
    data['notes'] = this.notes
    data['startTime'] = this.startTime ? this.startTime.toISO() : <any>undefined
    data['endTime'] = this.endTime ? this.endTime.toISO() : <any>undefined
    data['isRecurring'] = this.isRecurring
    data['isAllDay'] = this.isAllDay
    data['isNew'] = this.isNew
    data['color'] = this.color
    data['rRule'] = this.rRule
    data['dtStart'] = this.dtStart ? this.dtStart.toISO() : <any>undefined
    data['until'] = this.until ? this.until.toISO() : <any>undefined
    data['ownerID'] = this.ownerID
    data['parentItemID'] = this.parentItemID
    data['lastEdited'] = this.lastEdited
      ? this.lastEdited.toISO()
      : <any>undefined
    data['lastViewed'] = this.lastViewed
      ? this.lastViewed.toISO()
      : <any>undefined
    data['createdWhen'] = this.createdWhen
      ? this.createdWhen.toISO()
      : <any>undefined
    data['isCanceled'] = this.isCanceled
    return data
  }
}

export interface ICalendarEntry {
  itemID: number
  subject: string
  notes: string
  startTime: DateTime
  endTime: DateTime
  isRecurring: boolean
  isAllDay: boolean
  isNew: boolean
  color: string
  rRule: string
  dtStart: DateTime | null
  until: DateTime | null
  ownerID: number
  parentItemID?: number | null
  lastEdited?: DateTime | null
  lastViewed?: DateTime | null
  createdWhen?: DateTime | null
  isCanceled?: boolean
}

export interface ICalendarEntryApi {
  itemID: number
  subject: string
  notes: string
  startTime: string
  endTime: string
  isRecurring: boolean
  isAllDay: boolean
  isNew: boolean
  color: string
  rRule: string
  dtStart: string | null
  until: string | null
  ownerID: number
  parentItemID?: number | null
  lastEdited?: string | null
  lastViewed?: string | null
  createdWhen?: string | null
  isCanceled?: boolean
}

export interface ICalDay {
  day: string
  events: ICalendarEntry[]
}

export interface IFetchCalendarByDatesPayload {
  startDate: string
  endDate: string
  viewType: number
}

export interface IFetchDeletedListPayload {
  pageNumber: number
  pageSize: number
}

export interface IHandleEntriesPayloads {
  events: ICalendarEntryApi[]
  startDate?: string
  endDate?: string
  day?: string
}

export interface IColorPickerUpdatePayload {
  color: string
  itemId: number
}

export interface IRRuleForm {
  freq: string
  until: Date | null | undefined
  interval: number
  byweekday: any
  bysetpos: any
  n?: number
}

export interface ICalendarForm {
  subject: IFormObj
  notes: IFormObj
  startTime: IFormObj
  endTime: IFormObj
}

export interface sortedCalendarArray {
  hour: string
  events: ICalendarEntry[]
}

export interface ITab {
  itemID: number
  name: string | null
  userID?: number
  caseID?: number
  createdWhen?: Date | null
  updatedWhen?: Date | null
}

export interface IField {
  itemID: number
  cardID: number
  content: string
  type: number
}

export interface ICard {
  itemID: number
  tabID: number
  tabName?: string
  userID?: number
  caseID?: number
  sharedUserID?: number
  shared: boolean
  title: string
  description: string | null
  fields: IField[]
  createdWhen?: Date | null
  updatedWhen?: Date | null
  viewedWhen?: Date | null
}

export interface ITemplateCard {
  templateName: string
  templateDescription: string
  description: string
  nameField: string
  phoneField: string
  emailField: string
  addressField: string
  cityField: string
  stateField: string
  zipField: string
}

export interface IGroupedCards {
  tabName: string
  cards: ICard[]
}

export interface IResult {
  changed: boolean
  valid: boolean
  value: any
  itemID?: number
  error?: string
}

export interface IFieldResult {
  changed: boolean
  valid: boolean
  value: any
  itemID?: number
  type?: number
}

export interface ITosResult {
  itemID: 37
  createdWhen: string
  lastModified: string
  termsOfServiceHtml: string
  type: number
}

export interface ISearchData {
  searchText?: string
  filterValue?: string
  sortValue?: string
  dateRange?: DateTime[]
}

export interface IFilterItem {
  name: string
  value: string
  ascending?: boolean
}

export interface ILocalStorage {
  key: string
  value: string
  local: boolean
  expires?: Date
}

export interface IRecordPurchase {
  datePurchased: Date
  emailForReceipt: string
  records: IRecord[]
}

export interface IRouterLinkToObject {
  name?: string
  path?: string
  query?: any
  params?: any
}

export interface ITimePickerOutput {
  hours: number
  minutes: number
  seconds?: number
}

export interface IUpdateScheduledPaymentRequest {
  id: number
  amount: number
  fee: number
  sourceFundingSourceId: number
  description: string
  scheduledWhen: Date
}

export interface ICreatePendingSignupResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: ICreatePendingSignupResult
}

export interface IVerifyPendingSignupResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IVerifyPendingSignupResult
}

export interface IResendVerifyCodeResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IResendVerifyCodeResult
}

export interface IAddAttachmentsProps {
  parentFileCount: number
  includeAttachmentList: boolean
  attachments: Array<IAttachment>
}

export interface IActivityListItem {
  type?: number
  id?: number
  title?: string
  amount?: number | undefined
  fee?: number | undefined
  totalAmount?: number | undefined
  status?: number
  description?: string
  descriptionInitials?: string
  sending?: boolean
  sourceCustomerId?: number
  destinationCustomerId?: number
  sourceFundingSourceId?: number
  destinationFundingSourceId?: number
  lastUpdatedWhen?: DateTime | undefined
  createdWhen?: DateTime | undefined
  lastProcessedWhen?: DateTime | undefined
  showView?: boolean
  sortByDate?: DateTime
  failureDisplay?: string
  payment?: IActivityListItem | null | undefined
  scheduledPayment?: IActivityListItem | null | undefined
  notes?: string
  viewedWhen?: DateTime | undefined
  relatedActivityTitle?: string
  noteInitials?: string
  subTitle?: string
  upComingScheduledWhen?: DateTime | undefined
  hasUpComingPayment?: boolean
  relatedActivityDescription?: string
  repeat?: boolean
  request?: IActivityListItem | null | undefined
  scheduledWhen?: DateTime | undefined
  moneyTransferRequestId?: number | null | undefined
  moneyTransferScheduledPaymentId?: number | null | undefined
  clearingType?: number | undefined
  skeletonLoading?: boolean | null | undefined
}

export interface ITransferRequest {
  id?: number | undefined
  amount?: number | undefined
  sourceCustomerId?: number | undefined
  destinationCustomerId?: number | undefined
  destinationFundingSourceId?: number | undefined
  status?: number | undefined
  description?: string | undefined
  descriptionInitials?: string
  notes?: string | undefined
  caseID?: number | undefined
  lastUpdatedWhen?: Date | undefined
  viewedWhen?: Date | undefined
  createdWhen?: Date | undefined
  correlationId?: string
  externalCreatedWhen?: string
  externalId?: string
  failureCode?: string | undefined
  failureDisplay?: string | undefined
  fee?: string | undefined
  moneyTransferRequestId?: string | undefined
  moneyTransferScheduledPaymentId?: string | undefined
  sourceFundingSourceId?: number | undefined
  totalAmount?: string | undefined
  userInitials?: string | undefined
}

export interface IScheduledPayment {
  id?: number | undefined
  amount?: number | undefined
  fee?: number | undefined
  feeType?: number | undefined
  sourceCustomerId?: number | undefined
  sourceFundingSourceId?: number | undefined
  destinationCustomerId?: number | undefined
  moneyTransferRequestId?: number | undefined
  caseID?: number | undefined
  description?: string | undefined
  status?: number | undefined
  repeat?: boolean | undefined
  scheduledWhen?: Date | undefined
  lastProcessedWhen?: Date | undefined
  lastUpdatedWhen?: Date | undefined
  createdWhen?: Date | undefined
  totalAmount?: number | undefined
  upComingScheduledWhen?: Date | undefined
  hasUpComingPayment?: boolean | undefined
  title?: string
  subTitle?: string
  descriptionInitials?: string
  sending?: boolean
  destinationFundingSourceId?: number
  showView?: boolean
  failureDisplay?: string | undefined
  request?: ITransferRequest | undefined
  clearingType?: number | undefined
}

export interface IPayment {
  id?: number
  title?: string
  amount?: number | undefined
  fee?: number | undefined
  totalAmount?: number | undefined
  status?: number
  description?: string
  descriptionInitials?: string
  sending?: boolean
  sourceFundingSourceId?: number
  destinationFundingSourceId?: number
  lastUpdatedWhen?: Date | undefined
  createdWhen?: Date | undefined
  showView?: boolean
  failureDisplay?: string
  clearingType?: number | undefined
}

export interface IFundingSource {
  id?: number | undefined
  moneyTransferCustomerId?: number | undefined
  externalId?: string | undefined
  name?: string | undefined
  bankName?: string | undefined
  removed?: boolean | undefined
  removedFromThirdPartySystem?: boolean | undefined
  defaultDestination?: boolean | undefined
  type?: number | undefined
  status?: number | undefined
  microDepositStatus?: number | undefined
  avStatus?: number | undefined
  microDepositVerifyAttempts?: number | undefined
  accountHolderName?: string | undefined
  fingerPrint?: string | undefined
  createdWhen?: DateTime | undefined
  needsLinkUpdate?: boolean | undefined
  hasExpressPay?: boolean | undefined
  promptPlaidUpgrade?: boolean | undefined
  upgradeFundingSourceId?: number | undefined
  upgradedByFundingSourceId?: number | undefined
}

export interface ILayoutOptions {
  pageType: number
  headerText?: string
  subHeaderText?: string
  showHeaderAvatar?: boolean
  avatarIsParent?: boolean
  showBackbutton?: boolean
  backButtonRouteName?: IRouterLinkToObject
  backButtonLabel?: string
  showPaymentButtons?: boolean
  showDwollaBalance?: boolean
  showUpgradeBanner?: boolean
  showAdsNotInList?: boolean
  showAddNewButton?: boolean
  showCalendarNotificationsButton?: boolean
  showInfoButton?: boolean
  upgradeBannerForewordMessage?: string
  upgradeBannerMessageTier0?: string
  upgradeBannerMessageTier1?: string
  showShadow?: boolean
  showServiceMark?: boolean
  showMobileHeader?: boolean
  appLayoutHeightOfViewport?: boolean
}

export interface ICreateAccountResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: ICreateAccountResult
}

export interface IValidateMatchCodeResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IValidateMatchCodeResult
}

export interface IValidateEmailMatchResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IValidateEmailMatchResult
}

export interface IUpdateMatchInformationResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IUpdateMatchInformationResult
}

export interface IEnableMfaSmsResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IEnableMfaSmsResult
}

export interface IDisableMfaSmsResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IDisableMfaSmsResult
}

export interface IDeleteUnmatchedAccountResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IDeleteUnmatchedAccountResult
}

export interface IUpdateInformationResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: IUpdateMatchInformationResult
}

export interface ISendMatchCodeResponse {
  success: boolean
  errorCode: number | null
  errorMessage: string | null
  value: ISendMatchCodeResult
}

export interface IRemoveFundingSourceRequest {
  fundingSourceId: IMoneyTransferIdRequest
  dependenciesRequest?: IUpdateFundingSourceDependenciesRequest | null
}

export interface IToast {
  showToast: boolean
  type: 'success' | 'warning' | 'danger' | 'express'
  text: string | undefined
  showCloseButton: boolean
}

export interface INotification {
  type: 'success' | 'warning' | 'danger'
  text: string | undefined
  showCloseButton: boolean
  showChevronButton: boolean
}

export interface IErrorModal {
  showErrorModal: boolean
  title: string | undefined
  errorMessage: string | undefined
  showGenericError: boolean
  showFixError: boolean
}

export interface IWelcomePagesViewed {
  messages: boolean
  attachments: boolean
  calendar: boolean
  moneyTransfer: boolean
  infoLibrary: boolean
  journal: boolean
  records: boolean
  vault: boolean
  calling: boolean
}

export interface IWelcomePageBullet {
  mainText: string
  secondaryText?: string
  bulletIcon: string | Function
  iconIsFA?: boolean
}

export interface IBadgeCounts {
  newMessageCount: number
  newCalendarEventCount: number
  newInfoLibraryCardCount: number
  newAccountablePaymentCount: number
  unseenVoicemailCount: number
}

export interface IActivityCounts {
  calendarEventCount: number
  callCount: number
  infoLibraryCardCount: number
  journalEntryCount: number
}

export interface IBreadcrumbEvent {
  crumbName: string
  folderDepth?: number
  breadcrumbData?: any
  highlight?: boolean
  disabled?: boolean
}

export type UpgradeFeatureName = 'messages' | 'attachments' | 'records'

export interface IUpgradeCTA {
  isClosed: boolean
  closedWhen: string
}

//an interface that combines the information provided in
//account info, conversation info, coparent info, and date time settings
//as these have significant overlap with each other, and their information
//is used across the entire app, not restricted to just one feature
export interface IFullUserInfo {
  userId?: number | undefined
  caseId?: number | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  fullName?: string | undefined
  initials?: string | undefined
  email?: string | undefined
  phoneNumber?: string | undefined
  sendNewMessageEmails?: boolean | undefined
  timeZoneId?: number | undefined
  timeZoneIdentifierString?: string | undefined
  timeZoneNameForMomentJs?: string | undefined
  dateFormatId?: number | undefined
  shortDateFormat?: string | undefined
  dateFormatName?: string | undefined
  mfaSmsEnabled?: boolean | undefined
  biometricsEnabled?: boolean | undefined
  brandConsistencyEnabled?: boolean | undefined
  coparentFirstName?: string | undefined
  coparentLastName?: string | undefined
  coparentFullName?: string | undefined
  coparentInitials?: string | undefined
  traceUser?: boolean | undefined
}

// Tracking

// export interface ITrackingIdentity {
//   talkingparents_id?: number
//   first_name?: string
//   last_name?: string
//   phone?: string
//   email_id: string
//   cookie?: string
//   matched?: boolean
//   consent?: boolean
// }

export interface ITrackingIdentity {
  talkingparents_id?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  phone?: string | undefined
  email?: string | undefined
  matched?: boolean | undefined
  consent?: boolean | undefined
}

export interface ITrackingUpdate {
  email?: string | undefined
  first_name?: string | undefined
  last_name?: string | undefined
  phone?: string | undefined
  cookie?: string | undefined
  matched?: boolean | undefined
}

export interface ITrackingEvent {
  feature?: string | undefined
  step?: number | undefined
  field?: string | undefined
  action?: string | undefined
  name?: string | undefined
  timestamp?: string | undefined
  source?: string | undefined
  to_location?: string | undefined
  from_location?: string | undefined
}

export interface ITrackingIdentifyWithIdFunction {
  (payload: ITrackingIdentity): void
}
export interface ITrackingTrackFunction {
  (eventType: string, payload: ITrackingEvent): void
}
export interface ITrackingUpdateFunction {
  (payload: ITrackingUpdate): void
}
export interface ITrackingGetConsentUrlFunction {
  (payload: ITrackingIdentity): string
}
export interface IAnonymizelFunction {
  (): string
}

export interface IVaultFolder {
  name?: string
  itemID?: number
  parentItemID?: number
  description?: string
  createdDate?: Date
  isStarred?: boolean
  children?: Array<IVaultFolder> | undefined
}
// End Tracking

export interface IUpdateFundingSourceAfterUpdateRequest {
  fundingSourceId: number
}

//mobile controller request objects
export interface IGetVaultItemsParam {
  parentItemID?: number
  pageNumber: number
  pageSize: number
  sortBy?: number
}

export interface ISearchFilterSortVaultItemsParam {
  parentItemID?: number | null
  pageNumber: number
  pageSize: number
  rangeStart?: string
  rangeEnd?: string
  prioritizeStarred?: boolean
  searchTerm?: string
  filterBy?: number
  sortBy?: number
}

export interface IGetVaultShareLinkParam {
  itemID: number
  expiresWhenTicks: number
}

//temp calling request dtos
export interface IGetCallsRequest {
  filter: number
  pageNumber: number
  searchTerm: string
  pageSize: number
}

export interface IGetVoicemailsRequest {
  pageNumber: number
  searchTerm: string
}

export interface ICompleteCallingSetupRequest {
  enablePhoneCalling: boolean
  enableVideoCalling: boolean
}

export interface ISendCoparentReminderRequest {
  requestVideoCalling: boolean
  requestPhoneCalling: boolean
}

export interface ISetAllowsVideoCallsRequest {
  allowsVideoCalls: boolean
}

export interface IBuyMinutesPostRequest {
  planItemId: number
  orderId: string
  cardToken: string
}

export interface IFirstLegPostRequest {
  session: string
  userToNumber: string
  proxyNumber: string
  attempt: number
}

export interface IGatherRequest {
  called?: string | undefined
  digits: string
  speechResult?: string | undefined
  toState?: string | undefined
  callerCountry?: string | undefined
  direction?: string | undefined
  callerState?: string | undefined
  toZip?: string | undefined
  callSid?: string | undefined
  to?: string | undefined
  callerZip?: string | undefined
  toCountry?: string | undefined
  finishedOnKey?: string | undefined
  apiVersion?: string | undefined
  calledZip?: string | undefined
  calledCity?: string | undefined
  callStatus?: string | undefined
  from?: string | undefined
  accountSid?: string | undefined
  calledCountry?: string | undefined
  callerCity?: string | undefined
  caller?: string | undefined
  fromCountry?: string | undefined
  toCity?: string | undefined
  fromCity?: string | undefined
  calledState?: string | undefined
  fromZip?: string | undefined
  fromState?: string | undefined
}

export interface ICallStatusCallback {
  accountSid: string
  sessionSid: string
  apiVersion: string
  callSid: string
  direction: string
  duration: string
  timestamp: string
  callStatus: string
  callDuration: string
  callbackSource: string
  sequenceNumber: string
  sipResponseCode: string
  to: string
  toZip: string
  toCity: string
  toState: string
  toCountry: string
  called: string
  calledZip: string
  calledCity: string
  calledState: string
  calledCountry: string
  caller: string
  callerZip: string
  callerCity: string
  callerState: string
  callerCountry: string
  from: string
  fromZip: string
  fromCity: string
  fromState: string
  fromCountry: string
}

export interface ITwilioProxyRecordingCallback {
  itemID: number
  accountSid: string
  callSid: string
  recordingSid: string
  recordingStatus: string
  recordingDuration: string
  recordingChannels: string
  recordingUrl: string
}

export interface INewSessionParam {
  threadID?: number
}

export interface IJoinRoomRequest {
  joinVideoCallItemID: number
}

export interface ICompleteRoomRequest {
  type: number
  itemID: number
}

export interface IUpdateCallRequest {
  itemID: number
  callType: number
  newStatus: number
  action: VideoCallAction
  actionData?: string
}

export interface IVaultItem {
  itemID: number
  userID: number
  parentItemID?: number
  blobName: string
  name: string
  description: string
  fileSize: number
  isFolder: boolean
  createdDate: Date
  isStarred: boolean
  isTrashed: boolean
  trashedDate?: Date
  mimeType: string
  hasThumbnail: boolean
  isDeleted: boolean
  isSubTrashed: boolean
  skeletonLoading?: boolean
  thumbnailStatus?: VaultThumbnailStatus
}

export interface INewFolderResponse {
  blobName: string
  itemID: number
}

export interface IUploadTokenResponse {
  blobName: string
  tokenUri: string
}

export interface IFileBlocks {
  blockData?: Blob[]
  blockIds?: string[]
}

export interface IVaultUpload {
  uploadSasToken?: string
  blobName?: string
  file?: File
  parentItemID?: number
  blockData?: Blob[]
  blockIds?: string[]
  lastDataUploadedIndex?: number
  status?: string
  priority: number
  itemID?: number
}

export interface IDownload {
  id: string
  url: string
  progress: number
  fileName: string
  status: DownloadStatus
  mimeType?: string
}

export interface IVaultDownload {
  id: string
  url: string
  progress: number
  fileName: string
  status: string
  vaultItem?: IVaultItem
}

export interface ICallingDownload {
  id: string
  url: string
  progress: number
  fileName: string
  status: string
  callingItem: ICallingItem
}

export interface IVaultQuotaInfo {
  quotaBytesUsed: number
  quotaDisplayName: string
  quotaID: number
  quotaMaxBytes: number
  userID: number
}

export interface WorkerRequest {
  id: string
  url: string
  options?: RequestInit
}

export interface IndexedDBWorkerRequest {
  id: string
  database: string
  objectStore: string
  key: string
  value?: any[]
  options?: RequestInit
  template?: object
}

export interface WorkerResponse {
  success: boolean
  data?: any
  error?: string
}

export type WorkerCallback = (response: WorkerResponse) => void

export interface IGetOrCreateThumbnailSasUrlRequest {
  itemID: number
  width?: number
  height?: number
}

export interface IThumbnailFetch {
  workerId: string
  itemID: number
  urls: string[] | null
}

export interface IThumbnailSize {
  width: number
  height: number
}

export interface IVideoCall {
  itemID: number
  userID?: number
  caseID?: number
  toUserID?: number
  createdWhen?: Date
  status?: number
  channel?: string
  startTime?: Date
  endTime?: Date
  duration?: number
  threadID?: number
  transcriptJson?: string
  transcriptText?: string
  isTwilioVideo?: boolean
  flipTranscript?: boolean
  missedVoicemailRecording?: boolean
}

export interface IVideoCallRecording {
  itemID: number
  videoCallID?: number
  videoVoicemailID?: number
  createdWhen: Date
  channel?: string
  muxStatus: number
  muxTryCount: number
  videoBlobName?: string
  audioBlobName?: string
  transcriptCreatedDate?: Date
  muxStartedWhen?: Date
  muxEndedWhen?: Date
  muxDuration?: number
  rawFilesSavedWhen?: Date
  forceMux: boolean
  muxRawFiles: boolean
  isTwilioVideo: boolean
  audioMkaCount?: number
}

export interface ICallingItem {
  callType: CallingCallType
  direction: CallingCallDirection
  itemID: number
  caseID: number
  userID: number
  toUserID: number
  threadID?: number
  threadSubject?: string
  createdWhen?: DateTime
  startTime?: DateTime
  endTime?: DateTime
  viewedWhen?: DateTime
  status?: string
  finalCallingStatus?: FinalCallingStatus
  isMissedCall: boolean
  isUnseen: boolean
  skeletonLoading?: boolean
  missedVoiceMailRecording?: boolean
}

export interface ICallLog {
  itemID: number
  sessionSid: string
  fromUserName: string
  toUserName: string
  userID: number
  toUserID: number
  caseID: number
  duration: number
  startTime?: Date
  endTime?: Date
  recordingSid: string
  recordingDuration?: number
  status: string
  threadID?: number
  toName: string
  subject: string
  transcriptText: string
  transcriptCreatedDate?: Date
  recordingDownloadedOn?: Date
  transcriptJSON: string
  isFirstLegAccepted: boolean
  isSecondLegAccepted: boolean
  recordingCost?: number
  dateRecordingCostUpdated?: Date
  recordingRetryCount: number
  transcriptProvider: string
  recordingDeletedWhen?: Date
  flipTranscript: boolean
}

export interface ICallLogRecordingInfo {
  recordingSid: string
  recordingDuration?: number
}

export interface ISegment {
  speakerName: string
  isStart: boolean
  ts: number
  participantSid: string
  userID?: number
  segmentText: string
  skeletonLoading?: boolean
}

export interface IPhoneCallDetails {
  callLog: ICallLog | null
  segments: ISegment[] | null
  transcriptJobStatus: string | null
}

export interface IVideoCallDetails {
  videoCall: IVideoCall | null
  segments: ISegment[] | null
  recording: IVideoCallRecording | null
}

export interface IVideoCallNotification {
  status: string
  expiresWhen?: DateTime
}

export interface IRealTimeNotification {
  created: string
  expires: string
  roomId: number
  senderUserId: number
  type: string
}

export interface IRealTimeNotificationV2 {
  [key: string]: IRealTimeNotificationObject
}

export interface IRealTimeNotificationObject {
  conversationId?: number
  created: string
  expiration: string
  message: string
  messageId?: number
  recieverUserId: number
  senderUserId: number
  type: string
  transferRequestId?: number
  transferId?: number
  eventCount?: number
  itemId?: number
  roomId?: number
  senderName?: string
  newMessageCount?: number
  reportJobId?: number
  videoCallId?: number
}

export interface ICoparentCallingSettings {
  allowsVideoCalls: boolean
  allowsPhoneCalls: boolean
  isSubscriptionCapableOfReceivingVideoCall: boolean
}

export interface IVideoCallDetails {
  videoCall: IVideoCall | null
  segments: ISegment[] | null
}

export interface IMoveVaultItemsParam {
  itemIds: number[]
  parentItemId?: number
}

export interface IUpdateVideoCallStatusRequest {
  videoCallId: number
  newStatus: number
}

export interface IUpdateVoicemailStatusRequest {
  voicemailId: number
  newStatus: number
}

export interface ILogActionRequest {
  itemID: number
  callType: number
  action: VideoCallAction
  data: string
}

export interface ITrashOrRestoreVaultItemsParam {
  itemIds: number[]
  trash: boolean
}
export interface IStarVaultItemsParam {
  itemIds: number[]
  isStarred: boolean
}
//TODO testing

export interface ISearchedThreadItemPreview {
  preText: string
  searchedText: string
  postText: string
}

export interface ISearchedThreadItem {
  sourceItemId: number
  sourcePage: number
  preview: ISearchedThreadItemPreview
}

export interface ISearchedThread {
  threadID?: number | undefined
  subject?: string | undefined
  lastReplyDate?: Date | undefined
  hasCall?: boolean | undefined
  isNew?: boolean | undefined
  userID?: number | undefined
  threadItems?: MessagingThreadItem[] | undefined
  timeZoneIdentifier?: string | undefined
  lastReplyName?: string | undefined
  createDate?: Date | undefined
  createdBy?: string | undefined
  fromReplyID?: number | undefined
  searchedThreadItems?: ISearchedThreadItem[] | undefined
}

export interface IConversationSearchResult {
  messageID?: number | undefined
  conversationID: number
  type?: number | undefined
  searchScore?: number | undefined
  subject?: IHighlightedTextPreview | undefined
  isNew?: boolean | undefined
  messageDate?: Date | undefined
  messageCreatorID?: number | undefined
  conversationLastMessageDate?: Date | undefined
  conversationLastMessageCreatorId?: number | undefined
  conversationCreatorId?: number | undefined
  preview?: IHighlightedTextPreview | undefined
  rowNumber?: number | undefined
}
export interface IHighlightedTextPreview {
  prefix?: string | undefined
  highlightedText?: string | undefined
  suffix?: string | undefined
}

export interface IGroupedConversationSearchResult {
  replies?: IConversationSearchResult[]
  subject?: string
}

export interface IStarVaultItemsParam {
  itemIds: number[]
  isStarred: boolean
}

export interface ISingleCallReportParam {
  callLogItemID: number
  toEmail: string
  includeTranscripts: boolean
}

export interface ISortFilterFieldset {
  legend: string
  options: string[]
  selected: unknown[]
  type: SortFilterFieldsetTypes
}

export interface IConversationFilter {
  unread: boolean
}

export interface IDateRange {
  startDate: string
  endDate: string
}

export interface ISearchConversationsPagedRequest extends IPagedSearchPayload {
  ownerFilter?: OwnerFilter
  filterByType?: MessagesFilterByType
  unreadOnly?: boolean
  attachmentFileTypes?: FileType[]
  dateFilter?: IDateRange
  sortBy?: SortOption
}

export interface IGlobalSearchResult {
  type: GlobalSearchResultType
  message?: {
    itemID?: number
    content?: string
    creatorID?: number
    createDate?: DateTime
    highlightCount?: number
    isNew?: boolean
  }
  conversation?: {
    itemID?: number
    title?: string
    isNew?: boolean
    lastMessageDate?: DateTime
  }
  attachment?: {
    itemID?: number
    fileName?: string
    fileExtension?: string
    mimeType?: string
    fileSize?: number
    creatorID?: number
    uploadDateTimeUtc?: DateTime
    messageID?: number
    isNew?: boolean
  }
  calendarEvent?: CalendarEntry
  matchedTokens?: string[]
  skeletonLoading?: boolean
}

export interface ICachedReply {
  threadId: number
  userId: number
  message: ArrayBuffer
  messagePreview: ArrayBuffer
  attachments?: File[]
  iv: any
  previewIv: any
  salt: string
}

export interface ICachedReplyPreview {
  threadId: number
  userId: number
  messagePreview: ArrayBuffer
  previewIv: any
  salt: string
}

export interface IReplyDraft {
  threadId: number
  message: string
}

export interface IIndexedDBStatus {
  id: string
  success: boolean | null
  method: string
  data?: any
}

export interface IGetMoneyTransferCustomerResponse {
  id?: number
  userId?: number
  externalId?: string
  correlationId?: string
  status?: MoneyTransferCustomerStatus
  latestDocument?: IGetMoneyTransferCustomerDocumentResponse
  balance?: IGetBalanceResponse
  verificationStatus?: MoneyTransferCustomerVerificationStatus
  isLegacyUser?: boolean
  canMakePayments?: boolean
  expressPayIntroLastViewed?: DateTime
  createdWhen?: DateTime
  verified?: boolean
}

export interface IGetMoneyTransferCustomerDocumentResponse {
  id?: number
  moneyTransferCustomerId?: number
  externalId?: string
  fileName?: string
  type?: MoneyTransferDocumentType
  status?: MoneyTransferDocumentStatus
  failureReason?: MoneyTransferDocumentFailureStatus
  allFailureReasons?: string
  lastUpdatedWhen?: DateTime
  createdWhen?: DateTime
}

export interface IGetBalanceResponse {
  currentBalance?: Money
  negativeBalance?: IGetMoneyTransferNegativeBalanceResponse
}

export interface IGetMoneyTransferNegativeBalanceResponse {
  id?: number
  moneyTransferCustomerId?: number
  amountNegative?: number
  amountPaid?: number
  type?: MoneyTransferNegativeBalanceType
  causedByTransferId?: number
  clawbackSuccessful?: boolean
  lockCustomer?: boolean
  lockCoParentCustomer?: boolean
  closed?: boolean
  createdWhen?: DateTime
}

export interface IGetMoneyTransferFundingSourceResponse {
  id?: number
  moneyTransferCustomerId?: number
  externalId?: string
  name?: string
  bankName?: string
  removed?: boolean
  removedFromThirdPartySystem?: boolean
  defaultDestination?: boolean
  type?: MoneyTransferBankAccountType
  status?: MoneyTransferFundingSourceStatus
  microDepositStatus?: MoneyTransferMicroDepositStatus
  avStatus?: MoneyTransferAVStatus
  microDepositVerifyAttempts?: number
  accountHolderName?: string
  fingerPrint?: string
  needsLinkUpdate?: boolean
  hasExpressPay?: boolean
  promptPlaidUpgrade?: boolean
  upgradeFundingSourceId?: number
  upgradedByFundingSourceId?: number
  createdWhen?: DateTime
}

export interface IGetMoneyTransferResponse {
  id?: number
  externalId?: string
  correlationId?: string
  status?: MoneyTransferStatus
  amount?: number
  fee?: number
  sourceFundingSourceId?: number
  destinationFundingSourceId?: number
  moneyTransferRequestId?: number
  moneyTransferScheduledPaymentId?: number
  caseID?: number
  description?: string
  failureCode?: string
  failureDisplay?: string
  clearingType?: MoneyTransferClearingType
  createdWhen?: DateTime
  externalCreatedWhen?: DateTime
  lastUpdatedWhen?: DateTime
  totalAmount?: number
}

export interface IGetMoneyTransferRequestResponse {
  id?: number
  amount?: number
  sourceCustomerId?: number
  destinationCustomerId?: number
  destinationFundingSourceId?: number
  status?: MoneyTransferRequestStatus
  description?: string
  notes?: string
  caseID?: number
  lastUpdatedWhen?: DateTime
  viewedWhen?: DateTime
  createdWhen?: DateTime
}

export interface IGetMoneyTransferScheduledPaymentResponse {
  id?: number
  amount?: number
  fee?: number
  feeType?: MoneyTransferFeeType
  sourceCustomerId?: number
  sourceFundingSourceId?: number
  destinationCustomerId?: number
  moneyTransferRequestId?: number
  caseID?: number
  description?: string
  status?: MoneyTransferScheduledPaymentStatus
  repeat?: boolean
  scheduledWhen?: DateTime
  lastProcessedWhen?: DateTime
  lastUpdatedWhen?: DateTime
  createdWhen?: DateTime
  clearingType?: MoneyTransferClearingType
  totalAmount?: number
  upComingScheduledWhen?: DateTime
  hasUpComingPayment?: boolean
}

export interface IGetFundingSourceDependenciesResponse {
  pendingTransferRequests?: IGetMoneyTransferRequestResponse[]
  pendingScheduledPayments?: IGetMoneyTransferScheduledPaymentResponse[]
  pendingTransfers?: IGetMoneyTransferResponse[]
}

export interface ICreateMoneyTransferScheduledPaymentRequest {
  amount: number
  fee: number
  feeType?: MoneyTransferFeeType
  sourceCustomerId: number
  sourceFundingSourceId: number
  destinationCustomerId?: number
  moneyTransferRequestId?: number
  caseID?: number
  description?: string
  repeat?: boolean
  clearingType?: MoneyTransferClearingType
  scheduledWhen: string
  createdWhen?: string
}

export interface ICreateCustomerRequest {
  ssn: string
  dateOfBirth: string
  postalCode: string
  city: string
  address1: string
  address2?: string
  phone?: string
  type: string
  email: string
  lastName: string
  firstName: string
  state: string
  correlationId: string
}

export interface IRetryCustomerRequest {
  dateOfBirth: string
  postalCode: string
  city: string
  address1: string
  address2?: string
  phone?: string
  type: string
  email: string
  lastName: string
  firstName: string
  state: string
  ssn: string
}

export interface IMoneyTransferCustomerRetryRequest {
  moneyTransferCustomerId?: number
  externalCustomerId: string
  externalRequest?: IRetryCustomerRequest
}

export interface IConversation {
  threadID?: number
  subject?: string
  lastReplyDate?: DateTime
  hasCall?: boolean
  isNew?: boolean
  userID?: number
  threadItems?: IConversationItem[]
  timeZoneIdentifier?: string
  lastReplyName?: string
  createDate?: DateTime
  createdBy?: string
  lastReplyUserID?: number
  ownerUserID?: number
  lastReplyPreview?: string
  lastReplyFirstName?: string
  lastReplyLastName?: string
  ownerFirstName?: string
  ownerLastName?: string
  hasGoogleAd?: boolean
}

export interface IConversationItem {
  itemType?: ConversationItemType
  itemID?: number
  message?: string
  entryDate?: DateTime
  entryDateUtc?: DateTime
  altEntryDateUtc?: DateTime
  caseID?: number
  threadID?: number
  userID?: number
  name?: string
  fileName?: string
  initials?: string
  duration?: number
  attachments?: IAttachment[]
  isBlobDeleted?: boolean
  rowNumber?: number
}

export interface IAttachment {
  itemID?: number
  ownerID?: number
  threadID?: number
  replyID?: number
  fileName?: string
  fileExtension?: string
  fileSize?: number
  mimeType?: string
  blobName?: string
  uploadDateTime?: DateTime
  uploadDateTimeUtc?: DateTime
  isBlobDeleted?: boolean
  isArchived?: boolean
}

export interface IConversationViewModel {
  userID?: number
  subject?: string
  createDate?: DateTime
  timeZoneIdentifier?: string
  threadItems?: IConversationItem[]
}

export interface IGetPagedThreadResult {
  success?: boolean
  errorCode?: GetPagedThreadResultError
  errorMessage?: string
  value?: IConversationViewModel
}

export interface IJournalEntry {
  itemID?: number
  userID?: number
  caseID?: number
  message?: string
  createdWhen?: DateTime
  deleted?: boolean
  attachmentCount?: number
  title?: string
}

export interface IJournalAttachment {
  itemID?: number
  ownerID?: number
  journalEntryID?: number
  fileName?: string
  fileExtension?: string
  mimeType?: string
  blobName?: string
  uploadDateTime?: DateTime
  displayUploadDateTime?: DateTime
  fileSize?: number
}

export interface IToastifyData {
  text: string
  isExpress?: boolean
}

export interface IGetUnmatchedAccountInfoResult {
  matchCode?: string
  firstName?: string
  lastName?: string
  email?: string
  coparentEmail?: string
  coparentFirstName?: string
  coparentLastName?: string
  childFirstName?: string
  childBirthdate?: string
  timeZone?: string
  timeZoneId?: number
  mfaSmsEnabled?: boolean
  phoneNumber?: string
}

export interface IFeatureFlags {
  webFeatureFlagTest: boolean
  web_messages_enable3ColumnLayout: boolean
}

export interface ISearchChangeEvent {
  searchTerm: string
  oldSearchTerm: string
}

export interface IRowClickEvent {
  item: IConversation
}

export interface ISearchRowClickEvent {
  item: IGlobalSearchResult
}

export interface IDownloadAttachmentEvent {
  item: IGlobalSearchResult
}

export interface ICallIdForNewConversation {
  callLogItemID?: string | null
  videoCallItemId?: string | null
  videoVoicemailItemID?: string | null
}

export interface ICreateAccountRequest {
  pendingSignupGuid: string
  code: string
  firstName: string
  lastName: string
  password: string
  termsOfServiceAgreed: boolean
  appId: string
  timeZoneId?: number | undefined
  windowsTimeZoneId?: string | undefined
  rewardfulReferralId?: string | null
}
